.row {
	margin-right: 0;
	margin-left: 0;
	padding-right: $val-spacing-m;
	padding-left: $val-spacing-m;

	& & {
		// row inside row
		padding: 0;
	}

	&--padded {
		padding-top: $val-spacing-l !important;
		padding-bottom: $val-spacing-l !important;

		& + & {
			padding-top: 0 !important;
		}
	}
	&--padded-medium {
		padding-top: $val-spacing-m !important;
		padding-bottom: $val-spacing-m !important;

		& + & {
			padding-top: 0 !important;
		}
	}

	&--full-padded {
		padding: $val-spacing-l;
	}
	
	&--padded-top {
		padding-top: $val-spacing-l !important;
	}

	&--padded-bottom {
		padding-bottom: $val-spacing-l !important;
	}

	&--final-padding {
		// Important in cases where we need to have a padding after last input element (they have a margin bottom)
		padding-bottom: $val-spacing-s !important;
	}

	&--reverse-margin {
		margin-left: -$val-spacing-l !important;
		margin-right: -$val-spacing-l !important;
	}

	&--non-padded {
		padding: 0 !important;
	}

	&--submit {
		padding: $val-spacing-l;
		display: flex;
		justify-content: flex-end;

		> * {
			margin: 0 2px;
		}

		> :last-child {
			margin-right: 0;
		}
	}

	&--flex {
		display: flex;
		flex-wrap: wrap;

		@media (max-width: $bp-xxs-high) {
			flex-flow: column;
		}
	
		@media (min-width: $bp-xs-low) {
			flex-flow: row;
		}
	}

	&--centered {
		text-align: center;
	}

	&--column-aligned {
		display: flex;
		align-items: center;
	}
}

@media (min-width: $bp-xs-high) {
	.hidden-md {
		display: none;
	}
}