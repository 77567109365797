@import "../../../../../../styles/Colors.scss";
@import "../../../../../../styles/Constants.scss";

.modalHeader {
    background-color: $col-greyBackground;
    color: $col-greyInactive;
    font-size: $val-fontSizeBig;
	font-weight: bold;
    display: flex; 
    
    .userName {
        color: $col-black;
        margin-left: $val-spacing-xs;
    }
}

.inheritedBodyProps {
	padding: 0 !important;
}

.modalBody {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	.noEntriesToShow {
		padding: $val-spacing-xl;
		font-size: $val-fontSizeRegular;
		align-self: center;
		color: $col-greyInactive;
	}

	.modalAlert {
		font-size: $val-fontSizeRegular;
		padding: $val-spacing-l;
		background-color: $col-greyInactive;
		color: $col-white;
		white-space: pre-line;
		line-height: $val-lineHeightRegular;
	}

	.timeCard {
		margin: $val-spacing-m $val-spacing-xxl;
		box-shadow: 0 2px 2px 0 $col-shadow;
	
		.timeCardHeader {
			justify-content: space-between;
			display: flex;
			flex-direction: row;
			font-size: $val-fontSizeXBig;
			padding: $val-headerHeightS;
			
			.headerIcon {
				width: $val-icon15;
				flex: 1;
			}

			.timeCardName {
				flex: 9;
				align-self: flex-start
			}
		
			.headerSettingsIcon {
				flex: 1;
				align-self: flex-end;
			}
		} 

		.timeCardEntriesAlert {
			background-color: $col-canceledRed;
			color: $col-white;
			font-size: $val-fontSizeRegular;
			padding: $val-spacing-s;
			margin-bottom: $val-spacing-xxxs;
		}
		.timeCardEntriesWarning {
			background-color: $col-orange;
			color: $col-white;
			font-size: $val-fontSizeRegular;
			padding: $val-spacing-m;
			margin-bottom: $val-spacing-xxxs;
		}
	}

	.timeCardDay{	
		.timeCardDate {
			padding: $val-spacing-m;
			background-color: $col-design-grey12;
			color: $col-greyInactive;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.totalTimeGeneral {
			background-color: $col-darkBlueBorder;
			display: flex;
			flex-direction: column;
			font-size: $val-fontSizeSmall;
			justify-content: space-between;
			color: $col-white;

			.firstRow {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-start;
				padding: $val-spacing-s $val-spacing-m $val-spacing-s;

				.time {
					font-size: $val-fontSizeRegular;
					font-weight: bold;
				}
			}
			.secondRow {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding: 0 $val-spacing-m $val-spacing-s;

				.iconAndTime {
					display: flex;
					align-items: normal;
				}

				& [class^="icon-"] {
					font-size: $val-fontSizeBig;
					margin-right: $val-spacing-xxs;
				}
			}
		}

		.emptyEntryRow {
			padding: $val-headerHeightS;
		}

		.connectorTop {
			margin-left: 3.5%;
			width: $val-spacing-xxxs;
			height: $val-spacing-s;
			background-color: $col-greyConnection;
		};

		.connectorTopWarn {
			margin-left: 3.5%;
			width: $val-spacing-xxxs;
			height: $val-spacing-s;
			background-color: $col-canceledRed;
		};

		.connectorBottom {
			margin-left: 3.5%;
			width: $val-spacing-xxxs;
			height: $val-spacing-s;
			background-color: $col-greyConnection;
		}

		.connectorBottomWarn {
			margin-left: 3.5%;
			width: $val-spacing-xxxs;
			height: $val-spacing-s;
			background-color: $col-canceledRed;
		}

		.timeSheetEntry {
			display: flex;
			flex-direction: row;
			padding: $val-spacing-s;

			.workTypeIcon{
				flex: 0.5;
				align-self: center;
				color: $col-darkBlueBorder;
				display: flex;
				flex-direction: row;

				[class="icon-equipment"],
				[class="icon-travel"],
				[class="icon-home"],
				[class="icon-break"]  {
					font-size: $val-fontSizeXBig;
					position: relative;
				}
				
				.redIcon {
					color: $col-canceledRed;
				}

				.orangeIcon {
					color: $col-orange;
				}

				.inactiveIcon {
					color: $col-greyMid;
				}

				.greenIcon {
					color: $col-green;
				}

				// Positioning icon for indicating on-going entry
				[class="icon-time_card"]{
					font-size: $val-fontSizeSmall;
					color: $col-orange;
					position: absolute;
					left: 10.5%;
					transform: translate(50%, -50%);
  				}

				// Positioning icon for indicating rejected sheet
				[class="icon-x_circle"] {
					font-size: $val-fontSizeSmall;
					color: $col-canceledRed;
					position: absolute;
					left: 10.5%;
					transform: translate(50%, -50%);
  				}

				// Positioning icon for signed sheet
				[class="icon-check_circle"] {
					font-size: $val-fontSizeSmall;
					position: absolute;
					left: 10.5%;
					transform: translate(50%, -50%);
				}
			}

			.workTypeInfo{
				flex: 5;
				display: flex;
				flex-direction: column;

				.firstRow{
					display: flex;
					flex-direction: row;
					
					.workType {
						font-weight: bold;
						align-self: center;
					}

					.workOrderCode {
						margin-left: $val-spacing-s;
					}
					.unassigned {
						margin-left: $val-spacing-s;
						color: $col-canceledRed;
						font-size: $val-fontSizeSmall;
						align-self: center;
					}
				}

				.secondRow{
					display: flex;
					flex-direction: row;

					.overlapingTime {
						color: $col-canceledRed;
						font-size: $val-fontSizeSmall;
						padding: 0 $val-spacing-xxs;
					}

					.startEndTime {
						color: $col-greyInactive;
						font-size: $val-fontSizeSmall;
						padding: 0 $val-spacing-xxs;
					}
				}

				.workOrderCodeSeparator {
					margin-left: $val-spacing-s;
					border-left: 1px solid $col-greyFontLight;
  					height: auto;
				}
			}

			.workTypeDuration{
				flex: 1;
				font-weight: bold;
				display: flex;
				align-self: center;
				justify-content: center;
			}

			.workTypeEditIcon{
				flex: 0.5;
				display: flex;
				align-self: center;
				justify-content: center;
			}

			.activeTimer {
				color: $col-darkBlueBorder;
				font-weight: bold;
				display: flex;
				align-self: center;
				justify-content: center;
				font-size: $val-fontSizeBig;
			}
		}

		.gapRow{
			display: flex;
			flex-direction: row;
			align-items: normal;
			background-color: $col-orangeBorder;
			padding: $val-spacing-xs $val-spacing-s;
			color: $col-white;
			font-size: $val-fontSizeSmall;
			margin: $val-spacing-s 0;

			& [class^="icon-"] {
				font-size: $val-fontSizeBig;
				margin-right: $val-spacing-xs;
			}
		}
	}

	.emptySpace {
		background-color: $col-greyBackground;
		height: $val-spacing-xl;
	}

	.footerButtons{
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-self: center;
		
		.timeCardButton{
			height: $val-spacing-xxxl;
			border-radius: $val-fontSizeHuge;
			font-size: $val-fontSizeRegular;
			padding: $val-spacing-l
		}
	}
}

.linkText {
	color: $col-design-blue500;
}