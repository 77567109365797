@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

.job-work-summary {

	&__empty-cell {
		color: $col-greyFontLight;

		&--right-aligned {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}

	&__review-status-cell {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&--draft {
			[class^="icon-dot"] {
				margin-right: $val-spacing-s;
				color: $col-greyFontLight;
			}
		}

		&--in-progress {
			[class^="icon-dot"] {
				margin-right: $val-spacing-s;
				color: $col-orange;
				font-weight: bold;
			}
		}

		&--outdated {
			[class^="icon-dot_outline"] {
				margin-right: $val-spacing-s;
				color: $col-orange;
				font-weight: bold;
			}
		}

		&--reviewed {
			[class^="icon-dot"] {
				margin-right: $val-spacing-s;
				color: $col-darkBlueBorder;
				font-weight: bold;
			}
		}

		&--completed {
			[class^="icon-dot"] {
				margin-right: $val-spacing-s;
				color: $col-green;
				font-weight: bold;
			}
		}

		&--sub-row-cell {
			color: $col-greyFontLight;
		}

		&>b {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}