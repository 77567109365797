@import "../../../../../../../styles/Colors.scss";
@import "../../../../../../../styles/Constants.scss";

.timeline {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding-left: $val-spacing-l;
	padding-right: $val-spacing-m + 4px;
	padding-top: $val-spacing-s + 4px;
	background-color: $col-white;

	&__title {
		position: relative;
		top: 16px;
		min-width: 64px;
		margin-right: $val-spacing-m + 4px;
		font-weight: bold;
	}

	&__stripes-left-cover {
		position: absolute;
		left: 112px; // 64 + 24 + 24
		top: 0;
		width: 32px;
		height: 100%;
		background-color: $col-white;
		z-index: 2;
	}

	&__stripes-right-cover {
		position: absolute;
		right: 0;
		top: 0;
		width: 52px; // 20 + 32
		height: 100%;
		background-color: $col-white;
		z-index: 2;
	}

	&__bar-sections-container {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: end;
		width: 100%;
		height: 48px;
	}

	// We have a pattern 1px-10x background, 11px-11.5px colored stripe
	// the color in front (#EA8B00 0) helps with forcing browsers to draw the stripes unpixelated
	&__bar-container {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: end;
		width: 100%;
		height: 48px;
		padding-left: $val-spacing-l + 8px;
		padding-right: $val-spacing-l + 8px;
		background: repeating-linear-gradient(
			57.83deg, 
			#EA8B00 0,
			#FFF9EE 1px, 
			#FFF9EE 10px,
			#EA8B00 11px,
			#EA8B00 11.5px
		);
		z-index: 1;

		&__expand {
			margin-bottom: $val-spacing-m;
		}

		&__cover-left {
			padding-left: 0;
			margin-left: $val-spacing-l + 8px;
		}

		&__cover-right {
			padding-right: 0;
			margin-right: $val-spacing-l + 8px;
		}

		&__background-on-due-date {
			position: absolute;
			width: var(--percentageOfSectionOnDueDate);
			height: 48px;
			left: var(--left-offset);
			
			&--paint {
				background-color: $col-white;
			}

			&__text {
				position: absolute;
				top: 100%;
				height: 100%;
				width: 100%;
				color: $col-design-grey11;
				font-size: 10px;
				font-weight: 700;
				font-family: Roboto;
				cursor: default;
				white-space: nowrap;
				display: flex;
				justify-content: center;

				&--orange {
					color: #EA8B00;
				}

				&--hidden {
					display: none;
				}
			}
		}

		&__date-change-bar {
			position: absolute;
			background-color: #EA8B00;
			height: 48px;
			width: 0.5px;
			left: calc(var(--percentageFromLeft) + var(--adjustForGap));
			z-index: 3;

			&__background-cover {
				position: absolute;
				left: 1px;
				width: 2px;
				height: 100%;
				background-color: $col-white;
			}
	
			&__date-text {
				position: absolute;
				top: 100%;
				width: var(--textWidth);
				color: #EA8B00;
				font-size: 10px;
				font-weight: 700;
				font-family: Roboto;
				cursor: default;
				white-space: nowrap;

				@media (max-width: 800px) {
					display: none;
				}
	
				&__right {
					right: 0;
					transform: translateX(var(--translateRightPercentage));
				}
	
				&__left {
					left: 0;
					transform: translateX(var(--translateLeftPercentage));
				}
			}
		}
	}
}

.empty-timeline {
	height: 10px;
}
