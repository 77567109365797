@import '../../../styles/Constants.scss';

.navigationRight {
	display: flex;
		justify-content: flex-end;
		align-items: center;
		z-index: 0;

		& > * {
			margin-left: $val-spacing-xs;
		}

		& #table-and-display-view-dropdown-menu {
			min-width: unset;
			padding: 0px;
		}
}