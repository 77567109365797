$company-image-size: 65px;
$status-icon-size: 10px;
$company-custom-header: 200px;

.company-settings {

	&__row {
		display: flex;
		flex-flow: row wrap;
		padding: $val-spacing-l;
		justify-content: space-between;
		align-items: center;
	}

	&__image-segment {
		padding-right: $val-spacing-l;
	}

	&__image {
		border-radius: $company-image-size;
		width: $company-image-size !important;
		height: $company-image-size !important;
		border: 1px solid $col-greyMid;

		&--default {
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: $company-image-size;
			background-color: $col-greyLight;
			color: $col-greyFontLight;
			font-weight: bold;
			font-size: x-large;
		}
	}

	&__printout {
		height: inherit !important;
		margin-top: $val-spacing-m;
		max-height: $company-custom-header;
		background: $col-white !important;
	}

	&__printout-wrapper {
		width: 50%;
	}

	&__info-segment {
		display: flex;
		flex-flow: column wrap;
		justify-content: space-around;
		flex: 1 1 $company-info-basic-width;
	
		& > div [class^="icon-"] {
			width: $var-iconSize;
			font-size: $val-fontSizeXBig;
			margin-right: $val-spacing-m;
		}

		&__row {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;

			img {
				margin-right: $val-spacing-s;
			}
		}

		&__item {
			flex: 1;

			&:nth-child(odd) {
				padding-right: $val-spacing-s;
			}

			&:nth-child(even) {
				padding-left: $val-spacing-s;
			}
		}

		&__padded-bottom {
			padding-bottom: $val-spacing-s;
		}
	}

	&__edit-segment {
		float: right;
	}

	&__graphic-bullet {
		display: flex;
		& + & {
			margin-top: $val-spacing-s;
		}
		&:last-child {
			margin-bottom: $val-spacing-s;
		}
	}

	&__label {
		padding-right: $val-spacing-s;
	}

	&__value {
		font-weight: bold;

		&--inactive {
			font-weight: normal;
		}
	}

	&__selected-day {
		&--active {
			color: $col-orange;
		}

		& + & {
			margin-left: $val-spacing-m;
		}
	}

	&__empty {
		color: $col-greyFontLight;
	}

	&__feature-flag {
		display: flex;
		align-items: center;

		&--indented {
			margin-left: calc(#{$val-spacing-s} + #{$status-icon-size});
		}

		& + & {
			margin-top: $val-spacing-m;
		}
	}

	&__feature-status {
		font-size: $val-fontSizeRegular;
		margin-right: $val-spacing-s;
	}

	&__feature-days {
		display: flex;
		flex-wrap: wrap;
	}

	&__feature-day {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-right: $val-spacing-l;
	}

	&__auto-notify-checkbox {
		height: auto;
		padding-left: $val-spacing-m;
		padding-top: $val-spacing-m;
		margin-top: 0;
	}

}

.update-workdays, .update-resource-status {
	&__days .checkbox-inline {
		margin: $val-spacing-s;
		margin-left: 0;
	}

	&__item-list {
		padding-top: $val-spacing-m;
		width: 50%;
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		
		&:not(:last-child) {
			margin-bottom: $val-spacing-s;
		}
	}

	&__draggable-label {
		display: flex;
		align-items: center;
		margin-right: $val-spacing-m;
	}
	
	&__draggable-indicator {
		margin-right: $val-spacing-s !important;
		margin-left: -$val-spacing-xs;
	}

	&__remove-icon {
		font-size: $val-fontSizeBig !important;
		margin-right: 0 !important;
		color: $col-greyInactive;

		&:hover {
			color: $col-greyDark;
		}
	}
}

.update-resource-status {
	&__create-footer {
		border-top: none !important;
	}
}