@import "../../../../../../styles/Colors.scss";
@import "../../../../../../styles/Constants.scss";

.main-container {
	display: flex;
	justify-content: space-between;
}

.info-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.info-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: $val-spacing-s;
}

.info {
	display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 3px;
	margin: $val-spacing-s;
}

.info-block {
	padding: $val-spacing-s;
	box-shadow: 0px 0px 1px;
}

.revision {
	background-color: $col-design-orange5;
}

.current {
	background-color: $col-design-red4;
}

.field {
	font-weight: bold;
	margin-bottom: $val-spacing-s;
}

.field-name {
	font-size: $val-fontSizeXSmall;
}

.notes-field-name {
	color: $col-greyFontLight;
}

.group-name {
	color: $col-greyFontLight;
	font-size: $val-fontSizeSmall;
}

.group-info {
	display: flex;
	flex-direction: row;
	font-weight: bold;
	font-size: $val-fontSizeSmall;
	margin-right: $val-spacing-s;
}

.check {
	color: $col-green;
	margin-right: $val-spacing-s;
	font-size: $val-fontSizeRegular;
}

.clock {
	color: $col-orange;
	margin-right: $val-spacing-s;
	font-size: $val-fontSizeRegular;
}

.notes-field {
	margin-bottom: $val-spacing-s;
}

.map-link {
	font-weight: normal;
	color: $col-blue;
	text-decoration: underline;
}

.label-container {
	margin: 0;
	padding: 0;
	display: inline;
}

.label {
	padding: $val-spacing-xs $val-spacing-s;
	margin-bottom: $val-spacing-xs;
	display: inline-block;
	background-color: $col-white;
	box-shadow: 0px 0px 1px;
	font-weight: bold;
	width: 60%;
	text-transform: uppercase;

	&.dynamicBackgroundColor {
		background-color: var(--dynamic-color);
		color: $col-white;
	}

	&.dynamicTextColor {
		color: var(--dynamic-color);
	}
}