$avatar-size: 200px;
$avatar-container-size: $avatar-size + $val-spacing-l;

.avatar {
	width: $avatar-size;
	display: inline-block;
	margin-top: $val-spacing-l;
	margin-right: $val-spacing-m;

	& img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	&__container {
		width: $avatar-container-size;
		display: inline-block;
		margin-bottom: $val-spacing-m;
		margin-left: $val-spacing-s;

		&--double {
			width: $avatar-container-size * 2;
			@media (max-width: $bp-xxs-high) {
				display: flex;
				flex-flow: column;
			}
		
			@media (min-width: $bp-xs-low) {
				display: flex;
				flex-flow: row;
	
				& > div + div {
					margin-left: $val-spacing-m;
				}
			}
		}
	}

	&__neighbour {
		width: 100%;
	}

	&__label {
		margin-bottom: $val-spacing-s;
		display: block;
	}
}