.badges-container {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	z-index: 3;

	&__overlap-badges > :nth-child(2) {
		position: absolute;
		left: 0;
		top: 10px;
		z-index: 2;
	}
}