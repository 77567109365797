@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

$var-actions-top-offset: -16px;
$var-tabs-navigation-top-offset: 48px;

.job-form {

	&__submit-section {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: $val-spacing-xs;
		background-color: $col-white;
		padding: $val-spacing-m $val-spacing-l;
		z-index: 3;
		border-bottom: 2px solid $col-greyBackground;
		top: $var-actions-top-offset;
		position: sticky;

		&__hint {
			color: $col-greyFontLight;
			font-style: italic;
			flex: 1;
		}
	}

	&__tabs-navigation {
		position: sticky;
		top: $var-tabs-navigation-top-offset;
		z-index: 3;
	}

	&__tab {
		display: flex;
		gap: $val-spacing-xs;
		align-items: center;

		& > [class^="icon-warning"] {
			color: $col-canceledRed;
			font-size: $val-fontSizeBig;

		}
	}
}