@import "../../../../../../../styles/Colors.scss";
@import "../../../../../../../styles/Constants.scss";


.time {

	&__total-split-time-container {
		display: flex;
		flex-direction: row;
		gap: $val-spacing-m;
		justify-content: space-between;
	}

	&__total {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: $val-spacing-s $val-spacing-m;
		border-top-left-radius: $val-borderRadius;
		border-top-right-radius: $val-borderRadius;
	
		&--greyed {
			background-color: $col-greyBackground;
		}
	
		&--error {
			background-color: $col-redBackground;
		}
	
		&__tracked-entry {
			color: $col-blue;
			margin-right: $val-spacing-m;
			text-align: right;
			font-weight: bold;
		}
	}

}