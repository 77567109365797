.form-section-stacking-context {
	position: relative;
	z-index: 3;
}

.form-section-stacking-context :global(.form-control) {
	position: static !important;
	z-index: auto !important;
}

.form-section-stacking-context :global(.input-group) {
	position: static !important;
	z-index: auto !important;
}
