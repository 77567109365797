@import "../../../../../styles/Colors.scss";
@import "../../../../../styles/Constants.scss";

.pill {
	$var-borderRadius: 2px;
	$var-imageHeight: 40px;
	$var-imageMaxWidth: 200px;

	border-radius: $var-borderRadius;
	color: $col-white;
	display: flex;
	width: fit-content;
	font-size: $val-fontSizeSmall;
	font-weight: bold;
	line-height: $val-lineHeightRegular;
	padding: 0 $val-spacing-s;
	align-items: center;

	&--outdated {
		background-color: $col-orangeBorder;
	}

	&--signed {
		background-color: $col-blue;
	}

	&--unsigned {
		background-color: $col-orangeBorder;
	}

	&--approved {
		background-color: $col-green;
	}

	&--rejected {
		background-color: $col-red;
	}

	&--modal {
		margin-left: $val-spacing-s;
	}
}