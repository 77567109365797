@import "../../../../styles/Constants.scss";

.footerActions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: $val-spacing-l;

	& > * + * {
		margin-left: $val-spacing-xs;
	}
}