@import "../../../../../../../styles/Colors.scss";
@import "../../../../../../../styles/Constants.scss";

.actions-container {
	display: flex;
	flex-direction: row;
	gap: 4px;
	padding-bottom: 8px;
}

.timesheet-history {
	display: flex;
	flex-direction: row;
	gap: 8px;
	color: $col-design-blue500;
	font-size: $val-fontSizeRegular;
	padding-top: $val-spacing-xs;
	cursor: pointer;

	&:hover {
		color: $col-design-blue6;
	}

	&__icon {
		height: 26px;
		line-height: 26px;
		display: flex;
		align-items: center;
	}

	&__text {
		height: 26px;
		line-height: 26px;
		display: flex;
		align-items: center;

		@media (max-width: 700px) {
			display: none;
		}
	}

}

.edit {
	display: flex;
	flex-direction: row;
	gap: 8px;
	height: 26px;
	line-height: 26px;
	color: $col-design-blue500;
	font-size: $val-fontSizeRegular;
	padding-top: $val-spacing-xs;
	cursor: pointer;

	&:hover {
		color: $col-design-blue6;
	}

	&__icon {
		height: 26px;
		line-height: 26px;
		display: flex;
		align-items: center;
	}

	&__no-icon {
		margin-left: $val-spacing-l;
	}

	&--owner {
		color: $col-blue;
	}

	&__text {
		@media (max-width: 700px) {
			display: none;
		}
	}
}