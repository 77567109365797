@import "../../../../styles/Constants.scss";

.imageRow {
	display: flex;
	padding: $val-spacing-m $val-spacing-l;
	align-items: center;

	& > * + * {
		margin-left: $val-spacing-s;
	}
}