@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

$var-submit-action-top-offset: -16px;
$var-shadow: 0 1px 4px 0 $col-shadow;
$var-dropdown-width: 500px;

.order-header {

	&__left {
		display: flex;
		flex-direction: row;
		gap: $val-spacing-l;
		align-items: center;
	}

	&__right {
		display: flex;
		flex-direction: row;
		gap: $val-spacing-xs;
		align-items: center;
	}

	&__cost {
		font-weight: 700;
	}

	&__status-label {
		display: flex;
		flex-direction: row;
		gap: $val-spacing-s;
	}

	&__status {
		font-weight: 700;
		background-color: $col-design-grey13;
		font-size: $val-fontSizeSmall;
		line-height: $val-lineHeightSmall;
		min-width: 100px;

		display: grid;
		place-content: center;

		&--new {
			color: $col-blue;
		}

		&--in-progress {
			color: $col-orangeBorder;
		}

		&--completed {
			color: $col-green;
		}
	}
}

.order-details-row {
	display: flex;
}

.order-detail {
	display: flex;
	flex-direction: column;
	padding: $val-spacing-m 0;
	margin-right: $val-spacing-xl;

	&__label {
		color: $col-design-grey10;
	}

	&__value {
		padding: $val-spacing-s 0;
	}
}

.order-row {
	padding: 0 $val-spacing-xs;
}

$screen-padding: $val-spacing-m;
$section-padding: $val-spacing-m;
$column-padding: $val-spacing-xs;
$sidebar-width: 72px;

.order-row-table {
	width: calc(100vw - 2 * #{$column-padding} - 2 * #{$section-padding} - 2 * #{$screen-padding} - #{$sidebar-width});
}

.datepicker-field {
	width: fit-content;
}

.radio-group {
	padding-bottom: $val-spacing-m;
}

.address-field {
	.col-md-24 {
		padding: 0;
	}
}

.item-table-footer {
	&__action {
		display: flex;
		min-height: $val-spacing-xl;
		align-items: center;
		padding-left: $val-spacing-l;
		flex-direction: row;

		& > [class^="icon-plus"] {
			margin-right: $val-spacing-s;
		}
	}

	&__dropdown {
		display: flex;
		min-height: $val-spacing-xl;
		align-items: center;
		padding-left: $val-spacing-s;
		flex-direction: row;
	}

	&__action {
		color: $col-blue;
		cursor: pointer;
		width: fit-content;
	}

	&__dropdown {
		gap: $val-spacing-s;
	}
}

.item-dropdown {
	min-width: $var-dropdown-width;
}

.item-option {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $val-spacing-s;
	align-items: flex-start;

	&__details {
		font-size: $val-fontSizeSmall;
		color: $col-greyFontLight;
	}

	&__to-department-container {
		display: flex;
		flex-direction: row;
		gap: 8px;
	}
}

.dropdown-white-background {
	background-color: $col-white;
}

.dropdown {
	background-color: $col-white;
	width: 320px;
}
.order-form {
	display: flex;
	flex-direction: column;

	&__menu-option {
		width: 100%;
		margin-left: $val-spacing-s;

		&__text {
			display: flex;

			&__name {
				flex: 1;
				display: flex;
				gap: $val-spacing-xs;
			}
		}

		&__sub-text {
			color: $col-greyFontLight;
			font-size: $val-fontSizeSmall;
		}
	}

	&__equipment {
		display: flex;
		align-items: center;
		gap: $val-spacing-xs;
	}
}

.field-value {
	margin-top: $val-spacing-s;
	margin-left: $val-spacing-s;
	margin-bottom: $val-spacing-s;
	font-weight: bold;
	padding: $val-spacing-xs;
}

.row-value {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.change-order-type-footer {
	display: flex;
	flex-direction: row;
	gap: 16px;
}
