@import "./Colors.scss";

$val-sidebarWidthMin: 48px;
$val-sidebarWidthMax: 256px;
$val-floatingSidebarWidthMin: 48px;
$val-floatingSidebarWidthMax: 224px;
$val-headerHeightS: 16px;
$val-headerHeight: 64px;
$val-headerHeightXL: 80px;
$val-sidebarLeftPad: 24px;
$val-scrollBarHeight: 17px;
$val-textFilterMinWidth: 100px;
$val-serachBoxMaxWidth: 300px;

$val-fieldSize: 32px;
$val-colorSquareSize: 16px;
$val-colorSquareSizeS: 10px;
$val-colorSquareSizeL: 20px;

$val-linkHeightSmall: 36px;
$val-linkHeightMedium: 40px;
$val-linkHeightBig: 48px;
$val-linkHeightXBig: 64px;

$val-fontSizeXSmall: 10px;
$val-fontSizeSmall: 12px;
$val-fontSizeRegular: 14px;
$val-fontSizeBig: 16px;
$val-fontSizeXBig: 20px;
$val-fontSizeXXBig: 24px;
$val-fontSizeXXXBig: 26px;
$val-fontSizeHuge: 30px;
$val-fontSizeMax: 36px;

$val-lineHeightXSmall: $val-fontSizeXSmall + 2px;
$val-lineHeightSmall: $val-fontSizeSmall + 2px;
$val-lineHeightRegular: $val-fontSizeRegular + 2px;
$val-lineHeightBig: $val-fontSizeBig + 8px;
$val-lineHeightXBig: $val-fontSizeXBig + 16px;
$val-lineHeightXXBig: $val-fontSizeXBig + 18px;
$val-lineHeightXXXBig: $val-fontSizeXXXBig + 6px;
$val-lineHeightHuge: $val-fontSizeHuge + 8px;

$val-spacing-xxxs: 1px;
$val-spacing-xxs: 2px;
$val-spacing-xs: 4px;
$val-spacing-s: 8px;
$val-spacing-m: 16px;
$val-spacing-l: 24px;
$val-spacing-xl: 36px;
$val-spacing-xxl: 40px;
$val-spacing-xxxl: 48px;

$val-borderRadius: 2px;
$val-borderRadius-l: 5px;

$val-borderSize-s: 2px;
$val-borderSize-m: 4px;

$val-inputIconSize: 13px;
$val-inputPadding-tb: 8px;
$val-inputPadding-lr: 12px;
$val-inputPadding: $val-inputPadding-tb $val-inputPadding-lr;
$val-inputHeightS: 30px;
$val-inputHeight: 32px;
$val-inputHeightM: 34px;
$val-inputHeightXL: 40px;

$val-toggleAnimation: 0.5s cubic-bezier(0.45, 0.04, 0.15, 0.9);
$val-loadingCircleAnimation: spin-icon 1000ms infinite;

$val-icon6: 6px;
$val-icon8: 8px;
$val-icon10: 10px;
$val-icon12: 12px;
$val-icon14: 14px;
$val-icon15: 15px;
$val-icon16: 16px;
$val-icon20: 20px;
$val-icon24: 24px;
$val-icon30: 30px;
$val-icon48: 48px;
$val-icon56: 56px;
$val-icon64: 64px;

$bp-xl-low: 3790px;
$bp-l-high: $bp-xl-low - 1px;
$bp-l-low: 2510px;
$bp-m-high: $bp-l-low - 1px;
;
$bp-m-low: 1870px;
$bp-s-high: $bp-m-low - 1px;
$bp-s-low: 1230px;
$bp-xs-high: $bp-s-low - 1px;
$bp-xs-low: 992px;
$bp-xxs-high: $bp-xs-low - 1px;

$val-total-columns: 24;

$val-maxBoxSize: 1600px;

$val-modal-sm: 384px;
$val-modal-md: 590px;
$val-modal-lg: 800px;
$val-modal-xl: 1000px;
$val-modal-xxl: 1200px;

$val-boxShadow: 0 2px 2px 0 $col-shadow;
$val-sidenavZIndex: 6;

$val-notification: 367px;