@import '../../../../../../../styles/Colors.scss';
@import '../../../../../../../styles/Constants.scss';

.side-panel-modal {
	padding: 0 !important;

	&__block-container-primary {
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid $col-greyConnection;
		background-color: $col-blueBackground;
		border-left: 4px solid $col-darkBlueBorder;
		text-transform: uppercase;

		&--main {
			flex-direction: column;
		}
	}

	&__block-container-secondary {
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid $col-greyConnection;
		gap: 0 2*$val-spacing-xxl;
		padding: $val-spacing-m;
		text-transform: uppercase;

		&--main {
			background-color: $col-greyLight;
			flex-direction: column;
		}
	}

	&__block {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		text-transform: none;

		&:has(.side-panel-modal__field--adjustable) {
			grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
		}

		&--repeating {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		padding: 0 $val-spacing-m;
		gap: $val-spacing-m;
		flex: 1;

		&--primary {
			border-left: 4px solid $col-darkBlueBorder;
			background-color: $col-blueBackground;
		}

		&--secondary {
			background-color: $col-white;
		}

		&--secondary-main {
			background-color: $col-greyLight;
		}

		&__header-main {
			font-size: $val-fontSizeBig;
			padding: $val-spacing-m;
			color: $col-design-blue500;
			font-weight: bold;
			cursor: pointer;

			& > [class^="icon-"] {
				margin-left: $val-spacing-m;
				font-size: $val-fontSizeXBig;
			}
		}

		&__header-secondary {
			text-transform: uppercase;
			font-size: $val-fontSizeBig;
			font-weight: bold;
			display: flex;
			padding: 0 $val-spacing-m;
			align-items: center;
			justify-content: flex-start;
			height: 100%;
			cursor: pointer;

			& > [class^="icon-"] {
				margin-left: $val-spacing-m;
				font-size: $val-fontSizeXBig;
			}
		}

		&__title {
			padding: $val-spacing-l 0;
			white-space: nowrap;
		}
	}

	&__field {
		padding: $val-spacing-m 0;

		&--very-small {
			grid-column-end: span 1;
		}

		&--small {
			grid-column-end: span 2;
		}

		&--medium {
			grid-column-end: span 3;
		}

		&--large{
			grid-column-end: span 4;
		}

		&--largest {
			grid-column-end: span 12;
		}

		&--empty-line {
			grid-column-end: -1;
		}

		&--adjustable {
			gap: $val-spacing-s;
		}

		&--repeating {
			display: contents !important;
		}

		&__header {
			text-transform: none;
			font-weight: normal;
			margin-bottom: $val-spacing-s;
		}

		&__value {
			display: flex;
			flex-direction: column;
			font-weight: bold;

			& > img {
				width: 50px;
				height: 50px;
			}

			&--repeated {
				margin-bottom: $val-spacing-xs;

				& > img {
					width: 50px;
					height: 50px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__descriptive-text {
		color: $col-red;
		white-space: pre-wrap;

		&--black-text {
			color: $col-black;
		}

		&--bold-text {
			font-weight: bold;
		}
	}

	&__calculated-field {
		text-transform: capitalize;
	}

	&__completed-field {
		display: flex;
		grid-column: span 12;
		justify-self: end;
		gap: $val-spacing-s;
		justify-content: center;
		padding: $val-spacing-l;
	}

	&__signature-field {
		display: flex;
		text-transform: none;
		grid-column: span 4;

		& > img {
			width: 72px;
			height: 72px;
		}
	}
}
