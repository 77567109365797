@import "../../../../../styles/Colors.scss";
@import "../../../../../styles/Constants.scss";

.status-tooltip {

	&__time-sheet {
		display: flex;
		flex-flow: row;
		gap: $val-spacing-xs;
		margin-bottom: $val-spacing-s;
		margin-top: $val-spacing-m;

		&__label {
			font-size: $val-fontSizeXSmall;
		}
	}
}