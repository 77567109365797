@import "../../../../../../styles/Colors.scss";
@import "../../../../../../styles/Constants.scss";

$var-shadow: 0 2px 2px 0 $col-shadow;
$var-border: 1px solid $col-greyConnection;
$var-dot-icon-size: 6px;

.time-sheet-list {

	&__row {
		display: flex;
		flex-direction: column;
		box-shadow: $var-shadow;
		background: $col-white;
		margin-bottom: $val-spacing-s;

		&__name-and-action-section {
			padding-left: 24px;
			padding-right: 24px;
			padding-top: 8px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&__info {
			border-radius: $val-borderRadius;
			padding-left: 18px;
			padding-right: 18px;
			display: flex;
			flex-direction: row;
			gap: $val-spacing-l;

			&__time-card-link {
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $col-greyBackground;
				padding: $val-spacing-xs;
			}

			&__total-time-section {
				flex: 2;
			}

			&__info-section {
				padding: 8px;
				background-color: $col-greyLight;
			}

			&__no-icon {
				margin-left: $val-spacing-l;
			}

			&__edit {
				font-size: $val-fontSizeRegular;
				cursor: pointer;
				color: $col-design-blue500;
				padding-top: $val-spacing-xs;
				height: 26px;
				line-height: 26px;

				&:hover {
					color: $col-design-blue6;
				}

				&--owner {
					color: $col-blue;
				}
			}

			&--modified {
				background-color: $col-orangeHover;
			}

		}

		&__timeline-action {
			background-color: $col-white;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: row;
			border-bottom: 2px solid $col-greyBackground;

			&__button {
				color: $col-blue;
				cursor: pointer;
				margin-bottom: $val-spacing-s;

				&__icon {
					font-size: $val-fontSizeBig;
					margin-left: $val-spacing-s;
				}
			}
		}

		&__note {
			background-color: $col-greyLight;
			padding: $val-spacing-s;
			margin-top: $val-spacing-m;
	
			&__edit {
				&__header {
					display: flex;
					justify-content: space-between;
					align-items: center;

					&__button {
						height: 16px;
					}
	
					&__title {
						height: 16px;
						line-height: 16px;
					}

				}
	
				&__text {
					font-weight: bold;
					margin-top: $val-spacing-m;
				}

			}

			&__add {
				display: flex;
				justify-content: center;
				align-items: center;
			}
	
			&--readonly {
				background-color: $col-white;
			}
		}
		
	}
}
