$var-shadow: 0 2px 2px 0 $col-shadow;
$var-border: 1px solid $col-greyConnection;
$var-fieldSize: 48px;
$var-table-header-height: 64px;
$var-activeUnderlineShadow: 0px -6px 0px -3px $col-orange inset;

$var-rt-badge-width: 82px;

$var-coverOffset: 65px;

$var-pageSizeSectionWidth: 200px;
$var-pageSectionWidth: 220px;
$var-pageSizeInputWidth: 70px;
$var-paginationInputWidth: 60px;

.table {
	& tr {
		padding: 5px;
	}
}

.table-settings {
	&__item {
		display: flex;
		margin: $val-spacing-s 0;
		display: flex;
		align-items: center;
	}

	.icon-settings {
		color: $col-greyFontLight;

		&:focus,
		&:active,
		&:hover {
			color: $col-greyDark;
		}
	}
}

.form-box-table {
	& table {
		& thead {
			@extend .border-bottom;

			font-size: $val-fontSizeSmall;
			font-weight: normal !important;
			background: $col-greyLight;

			& tr th {
				height: $var-table-header-height;
				border: none;
				vertical-align: middle;
				display: table-cell;
				font-weight: normal !important;
			}
		}

		& tr {
			& th,
			& td {
				border: none;

				&:first-child {
					padding-left: $val-spacing-l;
				}

				&:last-child {
					padding-right: $val-spacing-l;
				}

				& + th {
					padding-left: $val-spacing-s;
				}

				&.limited-3 {
					width: 33%;
				}
			}
		}

		& tbody {
			& tr td {
				height: $var-fieldSize;
				vertical-align: middle;
				display: table-cell;
				font-size: $val-fontSizeRegular;

				&.action-column {
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}
			}

			& tr:nth-child(2n + 1) {
				background: $col-white;
			}

			& tr:nth-child(2n) {
				background: $col-greyLight;
			}
		}
	}

	& .form-box-table-total {
		border-top: 1px solid $col-blueBorder;
		background: $col-blueBackground !important;
		font-weight: bold;
	}
}

.rt-badge {
	width: $var-rt-badge-width;
	height: $val-lineHeightBig;
	border-radius: $val-borderRadius;
	vertical-align: middle;
	font-size: $val-fontSizeXSmall;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	color: $col-white;
	text-transform: uppercase;

	&--negative {
		color: $col-grey !important;
	}
}

.rt-status-icon {
	font-size: $val-fontSizeXXBig;
	font-weight: bold;

	&--orange {
		color: $col-orange;
	}

	&--green {
		color: $col-green;
	}
}

.table-container {
	background: $col-white;
	border-radius: $val-borderRadius;
	border: $var-border;
	-webkit-box-shadow: $var-shadow;
	box-shadow: $var-shadow;

	&__header-additional {
		height: $var-table-header-height;
		background-color: $col-orange;
		color: $col-white;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		transition: max-height $val-toggleAnimation;
		border-bottom: none;

		&--expanded {
			max-height: $val-fieldSize;
			padding: $val-spacing-s;
		}

		&--collapsed {
			max-height: 0;
			padding: 0;
			overflow: hidden;
		}
	}

	&__header-regular {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
		height: $var-table-header-height + 1px; // Accomodate input field
		background-color: $col-greyLight;
		padding: $val-spacing-m $val-spacing-l;
		border-bottom: $var-border;
	}

	&__actions {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-end;
	}

	&__filter {
		position: relative;
		display: flex;
		align-items: center;
		margin-right: auto;
		width: 50%;

		& input {
			min-width: $val-textFilterMinWidth;

			&::placeholder {
				color: $col-greyFontLight;
			}
		}

		&--stretch {
			width: 100%;
		}
	}

	&__expander-container {
		display: flex;
		align-items: center;
		color: $col-blue !important;

		&:hover {
			color: $col-blueHover;
		}

		.icon-expand,
		.icon-collapse {
			margin-left: $val-spacing-s !important;
			color: inherit !important;
		}
	}

	&__expander {
		font-size: $val-fontSizeXBig;
		cursor: pointer;
		color: $col-greyFontLight;

		&:hover {
			color: $col-greyDark;
		}
	}

	&__clear-filter,
	& .icon-search {
		position: absolute;
		right: $val-spacing-s;
		font-size: $val-fontSizeBig;
		color: $col-greyFontLight;
	}

	&__buttons {
		margin-left: $val-spacing-l;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		> * {
			margin: 0 4px;
		}

		> :last-child {
			margin-right: 0;
		}
	}

	&__row {
		@extend .table-container__header-regular;
		background: $col-white;
	}

	&--auto-height {
		.rt-table {
			overflow: visible !important; // needed for dropdowns in case of a small table
		}
		.rt-tbody {
			height: auto !important;
			overflow: visible !important; // needed for dropdowns in case of inner table
		}
		.rt-noData {
			position: relative;
			transform: unset;
			left: unset;
			display: flex;
			justify-content: center;
		}
	}

	&--tabbed:not(&--auto-height) {
		.rt-tbody {
			// Reduce height by the height of one header (it's usually 5x)
			height: calc(100vh - 6 * #{$val-headerHeight} - #{$val-spacing-s} - #{$val-scrollBarHeight}) !important;
		}
	}
}

.table-message-cover {
	position: absolute;
	left: 0;
	top: $var-coverOffset;
	background: $col-white;
	width: 100%;
	cursor: initial;
	font-weight: bold;
	color: $col-greyFontLight;
}

.column-actions {
	display: flex;
	font-size: $val-fontSizeSmall;

	& a + a {
		margin-left: $val-spacing-s;
	}
}

.column-title {
	@extend .segment-label;

	padding: 0;
	font-weight: normal;
}

.non-notifiable-row {
	background-color: $col-greyBackground;
	width: 100%;
}

.pagination {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: $val-spacing-s 0;

	&__page-size {
		width: $var-pageSizeSectionWidth;
		display: flex;
		align-items: center;
		margin-right: $val-spacing-s;

		& .react-select-field {
			margin-left: $val-spacing-m;
			width: $var-pageSizeInputWidth;
		}
	}

	&__page-number {
		width: $var-pageSectionWidth;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: $val-spacing-s;
		margin-right: $val-spacing-m;

		& .page-input {
			width: $var-paginationInputWidth;
			margin: 0 $val-spacing-s;
		}
	}
}

.cell-main-info {
	font-weight: bold;
}

.cell-multirow {
	display: flex;
	flex-direction: column;
}

.cell-additional-info {
	color: $col-greyFontLight;
	font-size: $val-fontSizeSmall;

	&__icon {
		margin-right: $val-spacing-s;
		font-weight: bold;
		font-size: $val-fontSizeRegular;

		&--minus {
			color: $col-red;
		}

		&--plus {
			color: $col-green;
		}
	}

	&--change-log {
		color: $col-greyDark;
		white-space: normal;
		display: flex;

		& .color-square {
			margin-right: $val-spacing-s;
		}
	}

	&__empty {
		color: $col-greyFontLight;
	}
}

.cell-description {
	font-family: "Menlo", "Monaco", "Consolas", "Courier New", monospace;
	font-size: $val-fontSizeXSmall;
	white-space: normal;
}

.crew-cell {
	&__crew-member {
		display: flex;
		align-items: center;
	}
}

.priority-cell {
	font-size: $val-fontSizeBig;
	font-weight: bold;

	&--low {
		color: $col-greyConnection;
	}

	&--medium {
		color: $color-orange;
	}

	&--high {
		color: $color-red;
	}
}

.email-cell,
.phone-cell {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	& span {
		margin-right: $val-spacing-m;

		&.label {
			margin-right: $val-spacing-s;
		}
	}
}

.skills-cell {
	display: flex;

	&__popover-row {
		display: flex;
		align-items: center;

		& + & {
			padding-top: $val-spacing-xs;
		}

		.color-square {
			margin-right: $val-spacing-s;
		}
	}
}

.date-cell {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;

	&--left {
		align-items: flex-start;
	}

	&__time {
		font-size: $val-fontSizeSmall;
	}

	&__additional-info {
		font-size: $val-fontSizeSmall;
		color: $col-greyFontLight;
		display: flex;
		justify-content: flex-end;

		& [class^="icon-"] {
			font-size: $val-fontSizeRegular;
			margin-left: $val-spacing-xs;
		}

		&:hover {
			& [class^="icon-"] {
				color: $col-greyDark;
			}
		}
	}

	&__due-date {
		&--early {
			color: $col-greyFontLight;
		}

		&--today {
			color: $col-green;
		}

		&--late {
			color: $col-orange;
		}
	}
}

.company-status-cell {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.link-cell {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: $val-spacing-s;

	&__label {
		font-weight: bold;
	}

	&__icon {
		color: $col-blue;
		font-size: $val-fontSizeXBig;
	}
}

.boolean-cell {
	display: flex;
	align-items: center;

	&__true {
		color: $col-green;
	}

	&__false {
		color: $col-red;
	}

	[class^="icon-"] {
		margin-right: $val-spacing-s;
		font-size: $val-fontSizeBig;
	}
}

.selectable-text-cell {
	user-select: text;
	display: block !important; // needed for ellipsis
	overflow: hidden;
	text-overflow: ellipsis;

	&:hover {
		cursor: text !important;
	}
}

.table-filter {
	display: flex;
	justify-content: flex-end;

	&__date {
		margin-right: $val-spacing-s;
		display: flex;

		.dropdown-item {
			// btn parent unintentionally sets it to bold
			font-weight: normal;
		}

		.dropdown {
			padding: 0;

			button {
				padding: 0 $val-spacing-s;
			}
		}
	}

	&__parameter {
		margin: 0 $val-spacing-s;
		display: flex;
	}
}
