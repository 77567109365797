$var-status-icon-container-size: $val-fieldSize;
$active-overline-shadow: 0px 8px 0px -2px $col-orange inset;
$active-overline-reject-shadow: 0px 8px 0px -2px $col-canceledRed inset;
$hover-overline-shadow: 0px 8px 0px -2px $col-orangeHover inset;

.field-report-list-table {
	&__row {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: $val-spacing-m $val-spacing-l;
		margin-top: $val-spacing-s;
		background: $col-white;
		border-radius: 2px;
		box-shadow: 0 2px 2px 0 $col-shadow;
		border: 1px solid $col-greyConnection;
		width: 100%;
	}

	&__row-actions {
		display: flex;
	}

	&__action-dropdown {
		.icon-actions {
			font-size: $val-icon30;
		}
	}

	&__row-header {
		display: flex;
		align-items: center;

		&__title {
			font-size: $val-fontSizeBig;
			font-weight: bold;
			margin-left: $val-spacing-xxs;

			&--deleted {
				color: $col-greyFontLight;
				text-decoration: line-through;
			}
		}
	}

	&__row-body {
		margin-left: $var-status-icon-container-size + $val-spacing-xxs;
		font-size: $val-fontSizeSmall;

		&>div {
			display: flex;
			margin-top: $val-spacing-s;
		}

		&__report-type-list {
			display: flex;
			flex-wrap: wrap;
		}

		&__report-type-item {
			margin-left: $val-spacing-xs;
			margin-right: $val-spacing-xs;
			display: flex;
			align-items: baseline;
		}

		&__report-type-icon {
			font-size: $val-icon10;
			width: $val-icon16;
			display: flex;
			justify-content: center;
		}

		&__report-type-name {
			text-transform: uppercase;
		}
	}

	&__row-details-btn {
		float: right;

		&:not(:hover) {
			// keep it white on row hover, but default to standard animation on button hover
			background-color: $col-white !important;
		}
	}

	&__create_btn_container {
		display: flex;
		justify-content: flex-end;
		margin: $val-spacing-l 0;
	}
}

.field-report-work-order-info-card {
	margin-bottom: $val-spacing-s;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $val-spacing-l;

		&__title {
			display: flex;
			align-items: center;

			&__name {
				color: $col-greyDark !important;
				font-weight: bold !important;
				font-size: $val-fontSizeXBig;
				margin-left: $val-spacing-s;
			}

			&__label {
				margin-left: $val-spacing-s;
				margin-top: $val-spacing-s;
			}

			&__canceled {
				color: $col-red !important;
				font-weight: bold !important;
				font-size: $val-fontSizeXBig;
				margin-left: $val-spacing-m;
			}

			&__project-indicator {
				margin-left: $val-spacing-m;
			}
		}

		&__actions {
			display: flex;
			& > * + * {
				margin-left: $val-spacing-xs;
			}
		}

		&__reviewed {
			&__time {
				color: $col-greyFontLight;
			}

			&--approved {
				color: $col-darkBlueBorder;
			}

			&--rejected {
				color: $col-canceledRed;
			}

			&--submitted {
				color: $col-orange;
			}

			&--finalized {
				color: $col-black;
			}
		}

		&__reject-reason-container {
			padding-top: $val-spacing-m;
			padding-bottom: $val-spacing-l;
			margin-right: $val-spacing-l;
			margin-left: $val-spacing-l;
			border-top: 1px solid $col-greyConnection;
		}

		&__reject-title {
			font-size: $val-fontSizeSmall;
		}

		&__reject-reason {
			font-size: $val-fontSizeRegular;
			margin-left: $val-spacing-s;
			color: $col-canceledRed;
		}

		&__pause-reason-container {
			padding-top: $val-spacing-m;
			padding-bottom: $val-spacing-l;
			margin-right: $val-spacing-l;
			margin-left: $val-spacing-l;
			border-top: 1px solid $col-greyConnection;
		}

		&__pause-title {
			font-size: $val-fontSizeSmall;
		}

		&__pause-reason {
			font-size: $val-fontSizeRegular;
			margin-left: $val-spacing-s;
			color: $col-greyFontLight;
		}

		&__virtual_review_info {
			padding-right: $val-spacing-s;
		}

		&__virtual_review_button {
			margin-left: $val-spacing-xl;
		}

		&__virtual_reviewed_by {
			font-weight: bold;
		}

		&__virtual_review_outdated {
			color: $col-orange-600;
			font-weight: bold;
		}
	}
}

.field-report-list {
	&__tabs {
		width: 100%;
		display: flex;
		gap: $val-spacing-m;

		&__tab {
			background-color: $col-white;
			display: flex;
			justify-content: space-between;
			align-items: center;
			transition: box-shadow 0.3s ease;
			width: 25%;
			height: fit-content;

			.icon-actions {
				margin-right: $val-spacing-s;
			}

			&:hover {
				cursor: pointer;
				box-shadow: $hover-overline-shadow;
			}

			&__content {
				padding: $val-spacing-m;
				display: flex;
				flex-direction: column;
				flex: 1;
				width: inherit;
			}

			&__title {
				font-weight: bold;
				font-size: $val-fontSizeBig;
			}

			&__subtitle {
				font-weight: normal;
				font-size: $val-fontSizeSmall;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}

			&__modified-icon {
				color: $col-orange;
				font-size: $val-fontSizeXSmall;
				vertical-align: top;

				&--inactive-rejected {
					color: $col-white;
				}
			}

			&__icon-edit {
				margin-top: -$val-spacing-m;
				vertical-align: top;
				font-size: $val-fontSizeXXBig;
				cursor: pointer;
				padding-right: 10px;
			}

			&__icon-search {
				margin-top: -$val-spacing-m;
				vertical-align: top;
				font-size: $val-fontSizeXXBig;
				cursor: pointer;
				padding-right: 10px;
			}

			&--active {
				box-shadow: $active-overline-shadow !important;
				padding-bottom: $val-spacing-m;
			}

			&--fit-content {
				width: unset;
			}

			&--active-rejected {
				box-shadow: $active-overline-reject-shadow !important;
				padding-bottom: $val-spacing-m;
			}

			&--inactive-rejected {
				background-color: $col-canceledRed;
				color: $col-white;
			}
		}
	}
}

.report-submit-modal {
	&__title {
		font-size: $val-fontSizeBig;
		font-weight: bold;
		margin-bottom: $val-spacing-s;
	}

	&__text {
		padding-bottom: $val-spacing-l;
	}

	&__radio {
		margin-bottom: $val-spacing-s;
	}

	&__loading {
		text-align: center;
		margin-top: $val-spacing-l;
	}

	&__section {
		padding-bottom: $val-spacing-l;

		&__title {
			text-transform: uppercase;
			color: $col-greyFontLight;
		}

		&__list {
			padding-top: $val-spacing-m;
		}

		&__list-item {
			margin: $val-spacing-xs 0;

			&+& {
				border-top: 1px solid $col-greyConnection;

				.report-submit-modal__field-report-title {
					margin-top: $val-spacing-m !important;
				}
			}

			&--expanded {
				background-color: $col-greyLight;
			}
		}
	}

	&__time-sheet-section {
		margin-top: $val-spacing-m;

		&__title {
			text-transform: uppercase;
			color: $col-greyFontLight;
			font-size: $val-fontSizeSmall;
		}
	}

	// field reports
	&__field-report-title {
		text-transform: uppercase;
		font-size: $val-fontSizeBig;
		font-weight: bold;
		padding-bottom: $val-spacing-xs;
	}

	&__field-report-info {
		font-size: $val-fontSizeSmall;
		padding-bottom: $val-spacing-s;
	}

	&__field-report-type-list {
		display: flex;
		flex-flow: row wrap;
		padding: $val-spacing-xs 0;
	}

	&__field-report-type-item {
		margin-right: $val-spacing-xs;
		display: flex;
		align-items: baseline;
	}

	&__field-report-type-name {
		text-transform: uppercase;
		font-size: $val-fontSizeSmall;
	}

	&__report-type-icon {
		font-size: $val-icon10;
		width: $val-icon16;
		display: flex;
		justify-content: center;
	}
}

.modal-time-sheet-employee {
	display: flex;
	justify-content: space-between;
	padding: $val-spacing-s;

	&__status {
		display: flex;
		gap: $val-spacing-m;
	}

	&__expander {
		font-size: $val-icon20;
		cursor: pointer;
	}

	&__header {
		background-color: $col-darkBlueBorder;
		color: $col-white;
		font-size: $val-fontSizeSmall;
		padding: $val-spacing-s $val-spacing-m;
		display: flex;
		flex-flow: column nowrap;

		&__title {
			font-weight: bold;
			display: flex;
			justify-content: space-between;
			margin-bottom: $val-spacing-s;
		}

		&__totals {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
		}

		&__total {
			display: flex;
			gap: $val-spacing-xs;
		}

		&__work-icon {
			font-size: $val-fontSizeBig;
			font-weight: bold;
		}

		&__entry-list {
			display: flex;
			flex-flow: column nowrap;
			font-size: $val-fontSizeSmall;
		}

		&__entry {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			padding: $val-spacing-s;
			font-weight: bold;
		}

		&__entry-content {
			display: flex;
			flex-flow: row nowrap;
			gap: $val-spacing-l;
		}

		&__entry-icon {
			font-size: $val-fontSizeBig;
			color: $col-darkBlueBorder;

			&--manual {
				color: $col-orange;
			}
		}

		&__entry-dates {
			display: flex;
			flex-flow: row nowrap;
			gap: $val-spacing-m;

			&--manual {
				color: $col-orange;
			}
		}

		&__entry-duration {
			font-weight: normal;
		}
	}
}

.work-order-report-reject-modal {
	&__work-order {
		margin-bottom: $val-spacing-l;
	}

	&__reject-reason {
		margin-bottom: $val-spacing-l;
	}

	&__level {
		width: 50%;
	}

	&__signee {
		margin-left: $val-spacing-s;
		color: $col-greyFontLight;
	}

	&__dropdown {
		margin-bottom: $val-spacing-m;
	}
}