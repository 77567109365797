$image-button-size: 92px; // Half of 200px image width and margin between them (16px)

.image-upload {

	&__buttons {
		display: flex;
		padding-top: $val-spacing-s;

		& > * + * {
			margin-left: $val-spacing-xs;
		}
	}

	&__row {
		display: inline-block;
	}
}