@import '../../../../../../styles/Colors.scss';
@import '../../../../../../styles/Constants.scss';
@import '../../../../../../components/Table/styles.module.scss';

$var-shadow: 0 2px 2px 0 $col-shadow;
$var-submit-action-top-offset: -16px;
$var-tabs-navigation-top-offset: 48px;

.invoice {

	&__table-header {
		font-size: $val-fontSizeSmall;

		&>span {
			text-transform: uppercase;
		}

		&>b {
			margin-left: $val-spacing-xs;
			margin-right: $val-spacing-l;
		}
	}

	&__sub-row-cell {
		color: $col-greyFontLight;
	}

	&__no-billing-code {
		color: $col-greyFontLight;
		font-style: italic;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__empty-cell {
		color: $col-greyFontLight;

		&--right-aligned {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}

	&__status-cell {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		&--draft {
			[class^="icon-draft"] {
				margin-right: $val-spacing-s;
				color: $col-design-gray6;
			}
		}

		&--invoiced {
			[class^="icon-invoice_status_invoiced"] {
				margin-right: $val-spacing-s;
				color: $col-orange;
			}
		}

		&--partially-paid {
			[class^="icon-check"] {
				margin-right: $val-spacing-s;
				color: $col-darkBlueBorder;
				font-weight: bold;
			}
		}

		&--paid {
			[class^="icon-check"] {
				margin-right: $val-spacing-s;
				color: $col-green;
				font-weight: bold;
			}
		}

		&--overpaid {
			[class^="icon-check"] {
				margin-right: $val-spacing-s;
				color: $col-red;
				font-weight: bold;
			}
		}

		&--sub-row-cell {
			color: $col-greyFontLight;
		}

		&>b {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.table-container__table__body {
		height: calc(100vh - $var-company-header-height - $var-breadcrumbs-header-height - $var-tabs-navigation-height - $var-table-header-height - $var-table-action-header-height - $var-table-footer-height - 4 * $val-spacing-xl - 6 * $val-spacing-m);
	}
}

.invoice-form {

	&__submit-section {
		display: flex;
		align-items: end;
		background-color: $col-white;
		padding: $val-spacing-m $val-spacing-l;
		z-index: 3;
		border-bottom: 1px solid #C5C8CB;
		top: $var-submit-action-top-offset;
		position: sticky;

		&__invoice-code-and-reminder {
			font-size: $val-fontSizeBig;
			font-weight: bold;
			flex: 1;
			display: flex;

			&__code {
				padding-right: $val-spacing-l;
				align-content: center;
			}
		}
	}

	&__tabs-navigation {
		position: sticky;
		top: $var-tabs-navigation-top-offset;
		z-index: 3;
	}

	&__tab-content {
		display: flex;
		flex-direction: column;
		gap: $val-spacing-m;
		margin-top: $val-spacing-s;

		&__form {
			background-color: #FAFAFA;			

			&__section {
				background-color: $col-white;
				box-shadow: $var-shadow;
				padding: $val-spacing-m $val-spacing-l;
				margin-top: $val-spacing-m;
				margin-bottom: $val-spacing-m;
				margin-left: $val-spacing-s;
				margin-right: $val-spacing-s;
	
				&__title {
					text-transform: uppercase;
					color: $col-design-gray5;
					font-size: $val-fontSizeSmall;
					margin-bottom: $val-spacing-m;
				}

				&__installment-notice {
					font-style: italic;
					color: $col-design-gray5;
					margin-bottom: $val-spacing-m;
				}
	
				&__row {
					display: grid;
					margin-bottom: $val-spacing-m;
					gap: $val-spacing-l;
					grid-template-columns: repeat(8, 1fr);
	
					&__column-1 {
						grid-column: span 1;
					}
	
					&__column-2 {
						grid-column: span 2;
					}
	
					&__column-4 {
						grid-column: span 4;
					}
	
					&__column-6 {
						grid-column: span 6;
					}
	
					&__column-8 {
						grid-column: span 8;
					}
	
					&__centered-column {
						display: flex;
						align-items: center;
					}
	
					&__column-invoice {
						grid-column: span 2;
					}
	
					&__column-total-paid {
						grid-column: span 2;
	
						&__paid {
							color: #08C547;
						}
	
						&__overpaid {
							color: #CF1414;
						}
					}
				}
			}
		}

	}

	&__delete-button {
		margin-top: $val-spacing-m;

		& > [class^="icon-delete"] {
			color: $col-grey;
			font-size: $val-fontSizeBig;
			cursor: pointer;
			margin-top: calc($val-spacing-l + $val-spacing-s);

			&:hover {
				color: $col-black;
			}
		}
	}

	&__tab {
		display: flex;
		gap: $val-spacing-xs;
		align-items: center;

		& > [class^="icon-warning"] {
			color: $col-canceledRed;
			font-size: $val-fontSizeBig;

		}
	}

	&__add-field-button {
		width: fit-content;
		color: $col-blue;
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-top: $val-spacing-m;

		& [class^="icon-plus"] {
			font-size: $val-fontSizeBig;
			margin-right: $val-spacing-s;
		}
		&:hover {
			color: $col-darkBlueBorder;
		}
	}

} 

.installments {
	&__invoiced {
		&__add_btn {
			margin-top: $val-spacing-l;
		}

		&__list {
			margin-top: $val-spacing-s;
			padding-bottom: $val-spacing-s;

			&__header {
				color: $col-greyDark;
				margin-bottom: $val-spacing-s;

				&__number {
					color: $col-design-gray5;
				}
			}

			&__items {
				padding-bottom: 20px;
			}

			&__item {
				&__actions {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					font-size: $val-fontSizeBig;
					//padding-top: $val-spacing-xs;
					padding-bottom: $val-spacing-s;
					padding-right: $val-spacing-m;

					&--blue-edit {
						[class^="icon-edit"] {
							color: $col-blue;
						}
					}

					&--red-delete {
						[class^="icon-delete"] {
							color: $col-canceledRed;
						}
					}

					span {
						color: $col-design-gray6;
						cursor: pointer;

						&.disabled {
							color: $col-design-gray5;
							cursor: not-allowed;
						}

						&:not(:first-child) {
							margin-left: $val-spacing-s;
						}
					}
				}

				&__preview {
					padding-top: $val-spacing-xs;
					padding-bottom: $val-spacing-xs;

					&__number {
						color: $col-design-gray5;
					}

					&__field {
						font-weight: bold;

						&--strong {
							color: $col-greyDark;
						}

						&--invalid {
							color: $col-canceledRed !important;
						}
					}

					&__download-preview {
						//justify-content: center;
						//display: flex;
						padding-left: $val-spacing-m;
						color: $col-blue;
						font-size: $val-fontSizeBig;
					}

					&--invalid {
						background-color: $col-orangeHover;
					}
				}

				&__edit {
					&:not(:first-child) {
						margin-top: $val-spacing-s;
					}

					&__actions {
						padding-top: $val-spacing-xl;
						font-size: $val-fontSizeBig;

						&--disabled {
							[class^="icon-check"] {
								color: $col-design-gray5;
								cursor: not-allowed;
							}
						}
	
						&--red-delete {
							[class^="icon-delete"] {
								color: $col-canceledRed;
							}
						}

						span {
							color: $col-design-gray6;
							cursor: pointer;
	
							&.disabled {
								color: $col-canceledRed;
								cursor: not-allowed;
							}
	
							&:not(:first-child) {
								margin-left: $val-spacing-s;
							}
						}
					}
				}
			}
		}
	}
}

.buttonActions {
	display: flex;
	& > * + * {
		margin-left: $val-spacing-xs;
	}
}