@import "../../../../../../../../styles/Colors.scss";
@import "../../../../../../../../styles/Constants.scss";

$var-shadow: 0 2px 2px 0 $col-shadow;
$var-border: 1px solid $col-greyConnection;
$var-dot-icon-size: 6px;

.gap {
	width: 2px;
}

.section-container {
	width: var(--widthPercentage);
	height: 100%;
	position: relative;

	&__fill-background {
		background-color: $col-white;
	}

	&__bar-container {
		display: flex;
		flex-direction: column;
		width: 100%;

		&--gap {
			margin-right: 2px;
		}
	}

	&__icon-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-top: 4px;
	}
}

.section {
	height: 8px;
	border-right: 1px solid $col-white;
	z-index: 2;

	&__icon {
		display: flex;
		justify-content: center;
		font-size: $val-fontSizeBig;
		margin-bottom: $val-spacing-xs;
		width: 16px;
		height: 16px;
		color: $col-blue;
		z-index: 2;
		
		&.unsigned-manual {
			color: $col-orange;
			background-color: white;
		}

		&.si-signed {
			color: $col-green;
			background-color: white;
		}

		&.overlap {
			color: $col-canceledRed;
			background-color: white;
		}
	}

	&__duration {
		display: flex;
		justify-content: center;
		font-weight: bold;
		margin-top: $val-spacing-xs;
	}

	&.entry {
		background-color: $col-blue;
	}

	&.occupied {
		background-color: $col-greyFontLight;
	}

	&.unsigned-manual {
		background-color: $col-orange;
	}

	&.si-signed {
		background-color: $col-green;
	}

	&.overlap {
		background-color: $col-canceledRed;
	}

	&.rounded-left {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	&.rounded-right {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
}
