@import "../../../../../../../styles/Colors.scss";
@import "../../../../../../../styles/Constants.scss";

.time-sheet-info {

	&__user-name {
		color: $col-greyDark;
		font-size: $val-fontSizeXBig;
		font-weight: bold;
		text-transform: uppercase;
		display: flex;
		flex-direction: row;
		margin-bottom: $val-spacing-xs;
		padding-top: $val-spacing-xs;
	
		&__warning-icon {
			margin-right: $val-spacing-s;
			color: $col-grey;
			cursor: pointer;
	
			&--not-approved {
				color: $col-canceledRed;
			}
		}
	
		&--owner {
			color: $col-blue;
		}
	}
	
	&__temporary-employee-icon {
		margin-right: $val-spacing-xs;
		font-size: $val-fontSizeXXBig;
	}
	
	&__uniqueId {
		font-size: $val-fontSizeSmall;
		color: gray;
		display: flex;
		flex-direction: column-reverse;
		margin-bottom: $val-spacing-xs;
	}

}

.time-sheet-signature {
	width: 214px;

	&__section {
		$var-borderRadius: 2px;
		$var-imageHeight: 40px;
		$var-imageMaxWidth: 72px;
	
		color: $col-greyDark;
		font-size: $val-fontSizeSmall;
		font-weight: normal;
	
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 12px;
	
		&+& {
			margin-top: $val-spacing-m;
		}
	
		&__superintendent {
			color: $col-red;
			margin: $val-spacing-s 0;
		}
	
		&__date {
			margin-left: $val-spacing-xs;
	
			&--block {
				display: block;
			}
		}
	
		&__name {
			margin-left: $val-spacing-xs;
		}
	
		&__image {
			margin-top: $val-spacing-s;
			height: $var-imageHeight;
			max-width: $var-imageMaxWidth;
		}
	
		&--inline {
			display: flex;
			margin-bottom: $val-spacing-m;
	
			.text-red {
				margin-left: $val-spacing-m;
			}
		}

		&__placeholder {
			margin-top: $val-spacing-s;
			height: $var-imageHeight;
			min-width: $var-imageMaxWidth;
		}
	}
}

