@import '../../../../../styles/Colors.scss';
@import '../../../../../styles/Constants.scss';
@import '../../../../../components/Table/styles.module.scss';

$var-shadow: 0 1px 4px 0 $col-shadow;
$var-submit-action-top-offset: -16px;
$var-tabs-navigation-top-offset: 48px;
$var-lost-for-reason-width: 250px;
$val-action-header-height: 2 * $val-spacing-m;

.rtv {

	&__empty-cell {
		color: $col-greyFontLight;

		&--right-aligned {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}

	.table-container__table__body {
		height: calc(100vh - $var-company-header-height - $var-breadcrumbs-header-height - $var-tabs-navigation-height - $var-table-header-height - $var-table-action-header-height - $var-table-footer-height - 4 * $val-spacing-xl - 6 * $val-spacing-m);
	}
}


@mixin cell-text {
	font-size: $val-fontSizeSmall;
}

.rtv-table {

	&__filter-button {
		border: 1px solid $col-greyMid;
		border-radius: $val-borderRadius;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		display: flex;
		align-items: center;
		background: $col-white;
	}

	&__selected-job {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

}

.side-panel-header {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: flex-start;
	margin-left: $val-spacing-l;
	text-transform: uppercase;
	font-weight: bold;

	& > [class^="icon-"] {
		cursor: pointer;
		color: $col-blue;
		font-size: $val-fontSizeXBig;
		margin-right: $val-spacing-m;
	}
}

.empty-table-message {
	color: $col-black;
}

.table-filters {
	width: 100%;
}

