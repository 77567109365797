@import "../../../../../../styles/Colors.scss";
@import "../../../../../../styles/Constants.scss";

.modalHeader {
	background-color: $col-greyBackground;
	color: $col-greyInactive;
	font-size: $val-fontSizeBig;
	font-weight: bold;
	display: flex; 
		
	.userName {
		color: $col-black;
		margin-left: $val-spacing-xs;
	}
}

.inheritedBodyProps {
	padding: 0 !important;
}

.modalBody {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding-bottom: $val-spacing-s;

	.modalMessage {
		font-size: $val-fontSizeRegular;
		padding: $val-spacing-m $val-spacing-l;
		color: $col-design-grey15;
		white-space: pre-line;
		line-height: $val-lineHeightRegular;
	}

	.numberOfSelected {
		font-size: $val-fontSizeRegular;
		padding: $val-spacing-s 0;
		font-style: italic;
		color: $col-greyInactive;
		white-space: pre-line;
		line-height: $val-lineHeightRegular;
	}
	.timeSheetEntry {
		display: flex;
		flex-direction: row;
		padding: $val-spacing-s $val-spacing-l;

		.workTypeIcon{
			flex: 0.5;
			color: $col-canceledRed;
			display: flex;
			align-self: center;
			justify-content: center;
			
			& [class^="icon-"] {
				font-size: $val-fontSizeXBig;
			}
		}

		.workTypeInfo{
			flex: 5;
			display: flex;
			flex-direction: column;

			.firstRow{
				display: flex;
				flex-direction: row;
				
				.workType {
					font-weight: bold;
				}

				.workOrderCode {
					margin-left: $val-spacing-s;
				}
				.unassigned {
					margin-left: $val-spacing-s;
					color: $col-canceledRed;
					font-size: $val-fontSizeSmall;
					align-self: center;
				}
			}

			.secondRow{
				display: flex;
				flex-direction: row;

				.startEndTime {
					color: $col-greyInactive;
					font-size: $val-fontSizeSmall;
					padding: 0 $val-spacing-xxs;
				}
			}

			.workOrderCodeSeparator {
				margin-left: $val-spacing-s;
				border-left: 1px solid $col-greyFontLight;
				height: auto;
			}
		}

		.workTypeDuration{
			flex: 1;
			font-weight: bold;
			display: flex;
			align-self: center;
			justify-content: center;
		}

		.workTypeEditIcon{
			flex: 0.5;
			display: flex;
			align-self: center;
			justify-content: center;
		}

		.checkbox {
			color: $col-darkBlueBorder;
			font-weight: bold;
			display: flex;
			align-self: center;
			justify-content: center;
			font-size: $val-fontSizeBig;
		}
	}
}

.footerButtons {
	& > * + * {
		margin-left: $val-spacing-xs;
	}
}