@import '../../../../../styles/Colors.scss';
@import '../../../../../styles/Constants.scss';

$badge-size: 15px;
$badge-offset: 8px;
$badge-border-size: 2px;

.down-resource-badge {
	background: $color-red;
	left: calc(100% - #{$badge-offset});
	top: $badge-offset - $badge-border-size;
	height: $badge-border-size * 2 + $badge-size;
	width: $badge-border-size * 2 + $badge-size;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: $val-fontSizeXSmall;
	color: $col-white;
	border-radius: 50%;
	border: $badge-border-size solid $col-white;
	z-index: 3;
}
