@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

$utilization-dropdown-width: 150px;
$utilization-working-bar-meter-height: 3px;
$utilization-working-bar-meter-max-width: 110px;

.text-red {
	color: $col-red !important;
}

.text-grey {
	color: $col-greyFontLight !important;
}

.utilization-view,
.utilization-group-view {

	& .bar-meter {
		margin-top: $val-spacing-xxs;
		background: $col-greyBackground;

		&__percentage {
			float: left;
			height: 100%;
			overflow: hidden;
		}
	}
}

.working .bar-meter {
	height: $utilization-working-bar-meter-height;
	max-width: $utilization-working-bar-meter-max-width;
}

.utilization-view {
	& .table-tabs {
		display: none;
	}

	& .dropdown-field {
		width: $utilization-dropdown-width;
	}

	&__title {
		text-transform: uppercase;
		font-weight: bold;

		margin-bottom: $val-spacing-m;
		font-size: $val-fontSizeXBig;
		display: block;
	}

	&__labor-skills-dropdown {
		margin-bottom: 0;
		width: $utilization-dropdown-width;
		margin-left: $val-spacing-s;

		& .checkbox-inline {
			margin-top: 0 !important;
		}
	}

	&__selected-labor-skills {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.skills {
			margin-right: $val-spacing-s;
		}
	}

	&__option-item {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-left: $val-spacing-s;
	}

	&__option-color-preview {
		margin-right: $val-spacing-m;
		height: $val-lineHeightRegular;
		width: $val-lineHeightRegular;
	}

	&__year-switch {
		display: flex;
		margin-left: $val-spacing-s;
	}

}