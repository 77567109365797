@import '../Constants.scss';
@import '../Colors.scss';

.modal-dialog {
	align-items: flex-start !important;
	padding-top: 12.5vh;
}

.user-guide-modal-body {
	margin: 24px !important;
	padding-right: 4px !important;
	padding-bottom: 24px !important;
}

.user-guide-header-title {
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;

	& .header-text {
		margin-left: 8px;
	}

	& .icon-help {
		font-size: 24px;
		width: 24px;
		height: 24px;
		line-height: 24px;
		text-align: center;
	}
}

.user-guide-modal-container {
	padding-left: 5px;
}

.user-guide-modal-navigation {
	position: sticky;
	top: 0px;
	z-index: 1000;
	height: 48px;
	padding: 8px 4px 8px 4px;
	background-color: $col-greyBackground;

	&__actions {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 32px;
		gap: 12px;
	}

	& .icon-right, & .icon-left {
		width: 24px;
		height: 24px;
		line-height: 24px;
		font-size: $val-fontSizeXBig;
	}

	& .icon-right:hover, & .icon-left:hover {
		cursor: pointer;
	}

	& .home-link{
		font-weight: 600;
	}
}

.modal-home {

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 12px;
	margin-top: 12px;

	&__link-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0px 8px 0px 8px;
		color: $col-blue;
		background-color: $col-blueBackground;
		height: 36px;
	}

	& .home-icon {
		width: 24px;
		height: 24px;
		line-height: 24px;
		font-weight: 500;
	}

	& .home-page-link{
		font-weight: 700;
	}
}

.modal-construction {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10px;
	gap: 15px;

	& .construction-title {
		text-align: center;
		font-size: 16px;
		font-weight: 700;
		width: 200px;
	}

	& .construction-text {

		& > p {
			text-align: center;
			width: 430px;
			font-size: 14px;
		}
	}

}

.user-guide {

	display: flex;
	flex-direction: column;
	gap: 12px;

	&__main-page-title {
		position: sticky;
		top: 48px;
		color: $col-blue;
		background-color: $col-blueBackground;
		height: 48px;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 12px;
		padding: 0px 8px 0px 8px;


		& .main-page-title-text {
			font-size: $val-fontSizeXBig;
			font-weight: 700;
		}

		& .page-icon {
			width: 24px;
			height: 24px;
			font-size: $val-fontSizeXXBig;
		}
	}

	&__page-title {
		position: sticky;
		top: 48px;
		color: $col-white;
		background-color: $col-orangeBorder;
		min-height: 48px;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 12px;
		padding: 0px 8px 0px 8px;


		& .page-title-text {
			font-size: $val-fontSizeXXBig;
			font-weight: 700;
		}

		& .page-icon {
			width: 24px;
			height: 24px;
			font-size: $val-fontSizeXXBig;
		}
	}

	&__sub-page-title {
		background-color: $col-blueBackground;
		color: $col-blue;
		padding: 0px 8px 0px 8px;
		min-height: 36px;
		line-height: 36px;
		font-size: $val-fontSizeXBig;
		font-weight: 700;
	}

	&__sub-title {
		font-size: $val-fontSizeXBig;
		font-weight: 700;
		min-height: 36px;
		line-height: 28px;
	}

	&__sub-title-2 {
		font-size: $val-fontSizeBig;
		font-weight: 700;
		min-height: 24px;
		line-height: 24px;
	}

	&__orange {
		color: $col-orange-600;
	}

	&__blue {
		color: $col-blue;
	}

	&__li {
		list-style-type: disc;
	}

	&__li-search {
		list-style-type: none;
		margin-left: -40px;
	}

	&__li-search > span:first-child {
		margin-left: var(--first-line-indent); 
		padding-left: 8px;
		margin-top: 0;
		margin-bottom: 0;
		display: block;
	}

	&__li-search > span:first-child::before  {
		content: '•'; /* Unicode for a bullet character */
		left: 0;
		font-size: 20px;
		color: black;
		margin-right: 8px;
		vertical-align: middle;
		height: 16px;
		line-height: 16px;
	}

	&__paragraph {
		margin: 0;
		padding: 0;
	}

	&__search-page-container {
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin-top: 4px;
	}

	&__search-page-text {
		padding: 8px 4px 8px 4px;
	}

	&__search-result {
		box-shadow: 0px 4px 8px $col-shadow;
	}

	&__search-result-highlight {
		background-color: $col-blueBackground;
	}

	&__search-input {
		position: relative;
		display: flex;
		align-items: center;
		width: 200px;
		
		& > input {
			border: 1px solid $col-greyMid;
			border-radius: $val-borderRadius;
			height: 100%;
			width: 100%;
			margin: 0;
	
			&::placeholder {
				color: $col-greyMid;
			}
		}
	
		& .icon-search, .icon-close {
			width: 16px;
			height: 16px;
			color: $col-greyMid;
			font-size: $val-fontSizeBig;
			position: absolute;
			right: $val-spacing-s;
		}
	
		& .icon-close {
			top: 8px;
		}
	}

	&__search-dropdown-container {
		position: fixed;
		padding: 8px;
		background-color: $col-white;
		box-shadow: 0px 4px 8px $col-shadow;
		width: 400px;
		z-index: 10000 !important;
		overflow-y: hidden;
	}

	&__search-dropdown {
		font-size: 12px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		overflow-y: auto;

		& .heading {
			margin-left: 6px;
		}
	}

	&__search-dropdown::-webkit-scrollbar {
		width: 5px;
	}

	&__search-dropdown::-webkit-scrollbar-track {
		background: $col-design-grey12;
	}

	&__search-dropdown::-webkit-scrollbar-thumb {
		background-color: #C5C8CB;
        border-radius: 4px;
        border: 2px solid #C5C8CB;
	}

	&__search-link {
		padding: 8px;
	}

	&__search-subtitles {
		display: flex;
		flex-direction: column;
		gap: 16px;
		font-size: 12px;

		& .section-container {
			margin-bottom: 16px;
		}

		& .section {
			color: $col-design-grey10;
			font-size: 10px;
		}

		& .subtitle-container {
			padding: 4px 8px 4px 8px;
			font-size: 12px;
		}

		& .subtitle-container:hover {
			background-color: $col-blueBackground;
			cursor: default;
		}

		& .subtitle-breadcrumbs {
			padding: 0px 8px 0px 8px;
			color: $col-greyInactive;
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: 10px;
			line-height: 12px;

			& .icon-right {
				height: 12px;
				width: 12px;
				line-height: 12px;
				font-size: 12px;
			}
		}

		& .subtitle-breadcrumbs>div{
			display: flex;
			align-items: center;
		}
	}

	&__search-breadcrumbs-container {
		display: flex;
		flex-direction: column;
		padding-bottom: 8px;
	}

	&__search-link:hover {
		background-color: $col-blueBackground;
		cursor: default;
	}

	&__image-container {
		text-align: left;
	}

	&__image {
		max-width: 100%;
		width: auto;
		height: auto;
		object-fit: contain;
	}

	&__link {
		font-weight: normal;
    	text-decoration: underline;
    	color: $col-darkBlueBorder; /* General Design Colours/Blue500 */
	}

	&__link:hover {
		text-shadow: 0 0 1px $col-darkBlueBorder; /* This is used instead of font-weight: bold, as that causes layout shift and makes links unclickable */
		text-decoration: underline;
		color: $col-darkBlueBorder;
	}
	
	&__link:active {
		font-weight: normal;
		text-decoration: underline;
		color: #0F0F10;
	}

	&__back-to-top {
		position: sticky;
		height: 56px;
		width: 56px;
		background-color: $col-blueHover; /* General Design Colours/Blue600 */
		bottom: -24px;
		left: 100vw;
		margin-right: 6px;
		color: $col-white;
		display: flex;
		flex-direction: column;
		font-weight: 400;
		border-radius: 13px;
		align-items: center;
		justify-content: center;

		& .icon-up {
			height: 12px;
			width: 12px;
			line-height: 12px;
			font-size: $val-fontSizeRegular;
			font-weight: 700;
		}
	}

	&__back-to-top:hover {
		cursor: pointer;
	}

	&__search-breadcrumbs {
		color: $col-greyInactive;
		padding: 0px 8px 8px 8px;
	}

	&__info-page-link-container {
		display: flex;
		flex-direction: row;
		padding-left: 32px;
		padding-right: 32px;
	}

	&__info-page-link-image-container {
		width: 24px;
		height: 24px;
		text-align: center;
	}

	&__info-page-link-image {
		width: 15px;
		height: 17px;
	}

	&__info-page-link {
		font-size: $val-fontSizeBig;
		font-weight: bold;
    	text-decoration: underline;
    	color: $col-darkBlueBorder; /* General Design Colours/Blue500 */
		height: 24px;
		line-height: 24px;
	}

}

.user-guide ul {
	margin: 0;
}
