$col-white: #FFFFFF;
$col-whiteShadow: rgba(255, 255, 255, 0.8);
$col-black: #1C1E21;
$col-blackShadow: rgba(0, 0, 0, 0.3);
$col-blackDark: #070708;
$col-blue: #005CB9;
$col-blueHover: #004387;
$col-blueBackground: #ECF5FF;
$col-blueBackgroundDark: #E2EFFF;
$col-blueHighlight: #C4E2FF;
$col-blueLine: #D4E7FF;
$col-blueBorder: #9FCAFF;
$col-darkBlueBorder: #1363B5;
$col-orange: #F49904;
$col-orangeBorder: #FFA726;
$col-orangeBackground: #FDEBD4;
$col-orangeHover: #FFF3E6;
$col-orangeHighlighted: rgba(
	$color: $col-orange,
	$alpha: 0.2
);
$col-grey: #9B9B9B;
$col-greyBackground: #F2F4F6;
$col-greyConnection: #DADDE2;
$col-greyMid: #C1C7CC;
$col-greyDark: #35383C;
$col-greyLight: #F9FAFB;
$col-greyFontLight: #A5ABB1;
$col-greyInactive: #959BA1;
$col-green: #08C547;
$col-red: #FF5F40;
$col-redBackground: #FDEDED;
$col-canceledRed: #ED4141;
$col-yellow: #F3C34F;

$col-orange-600: #EA8B00;
$col-orange-100: #FFF2DB;

$col-shadow: rgba(0, 0, 0, 0.1);
$col-curtain: rgba(0, 0, 0, 0.8);
$col-transparent: rgba(0, 0, 0, 0);
$col-disabled: #EEE;

// schedule board colors
$col-lightGrey: $col-greyLight;
$col-darkGrey: $col-greyDark;
$col-lighterGrey: #DADCDE;

// new schedule board colors
// added color prefix because we already have some colors defined
$color-brown: #684940;
$color-bronze: #AC761F;
$color-tan: #D8B894;
$color-maroon: #800102;
$color-red: #E6194B;
$color-peach: #FCAE9E;
$color-rust: #C84916;
$color-orange: #FF970D;
$color-apricot: #FFB469;
$color-ocher: #BA8A05;
$color-gold: #DBC01F;
$color-lemon: #FFFF99;
$color-forrestgreen: #026601;
$color-green: #4DB414;
$color-palegreen: #A7FB93;
$color-ultramarine: #1C04D3;
$color-blue: #3B44FF;
$color-babyblue: #BBD2F7;
$color-eggplant: #550266;
$color-purple: #9903CC;
$color-violet: #F685FF;
$color-crimson: #B4024D;
$color-ruby: #DD196D;
$color-cerise: #FF5D97;

// DESIGN COLORS:

$col-design-gray1: $col-greyLight;
$col-design-gray2: $col-greyBackground;
$col-design-gray3: $col-greyConnection;
$col-design-gray4: $col-greyMid;
$col-design-gray5: $col-greyFontLight;
$col-design-gray6: #555A5F;
$col-design-gray7: $col-greyDark;
$col-design-gray8: $col-black;
$col-design-gray9: $col-blackDark;
$col-design-grey10: #757C83;
$col-design-grey11: #2C2F31;
$col-design-grey12: #FAFAFA;
$col-design-grey13: #EFF0F1;
$col-design-grey14: #C5C8CB;
$col-design-grey15: #575D63;
$col-design-grey900: #0F0F10;

$col-design-orangePrimary: $col-orange;
$col-design-orange2: $col-orangeBorder;
$col-design-orange3: $col-orangeBackground;
$col-design-orange4: $col-orangeHover;
$col-design-orange5: #FFF9EE;
$col-design-orange6: #FFFBF4;

$col-design-blue1: $col-blueBackground;
$col-design-blue2: $col-blueBackgroundDark;
$col-design-blue3: #C5E2FF;
$col-design-blue4: $col-blueBorder;
$col-design-blue5: $col-darkBlueBorder;
$col-design-blue6: $col-blueHover;
$col-design-blue7: #BFD7F5;
$col-design-blue8: #65A9EF;

$col-design-blue50: #F1F7FE;
$col-design-blue200: #B9D8F8;
$col-design-blue500: #1363B5;

$col-design-green: $col-green;

$col-design-red1: $col-canceledRed;
$col-design-red2: $col-red;
$col-design-red3: $col-redBackground;
$col-design-red4: #FEF6F6;

// ENVIRONMENT COLORS:

$col-env-testing-bg: #05762B;
$col-env-testing-bg-light: #F9FEFB;
$col-env-staging-bg: #0F4F91;
$col-env-staging-bg-light: #F1F7FE;
