.user-upsert {
	&__section-label {
		margin-left: $val-spacing-l;
	}

	&__invite, &__existing {
		margin: 0 $val-spacing-l $val-spacing-l $val-spacing-l;
	}

	&__actions {
		padding: $val-spacing-l;
		display: flex;
		justify-content: flex-end;

		> * {
			margin: 0 2px;
		}
	
		> :last-child {
			margin-right: 0;
		}
	}

	&__user-group {
		margin: 0 $val-spacing-l;
	}

	&__image-addition {
		// Paddings already handled by parent
		& [class*='col-']:first-child {
			padding-left: 0 !important;
		}
	
		& [class*='col-']:last-child {
			padding-right: 0 !important;
		}
	}

	&__avatar {
		padding-bottom: $val-spacing-s;
	}

	&__details {
		padding: 0;
	}

	&__existing-toggle {
		& + & {
			margin-left: $val-spacing-l;
		}
	}

	&__rejected-members {
		box-shadow: 4px 0px 0px 0px $col-red inset !important;
	}

	&__rejected-members-table {
		margin-top: $val-spacing-m;
	
		& td {
			padding: $val-spacing-xs;
	
		}

		& span {
			margin-right: $val-spacing-l;
		}
	}

	&__rejected-param {
		font-weight: bold;
	}
	
	&__accepted-param {
		color: $col-greyFontLight;
	}
}
