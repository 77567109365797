@import "../../../../../styles/Colors.scss";
@import "../../../../../styles/Constants.scss";

.associated-subjob-data-container {
	display: flex;
	flex-direction: column;
	padding: $val-spacing-s;
	padding-left: $val-spacing-l;
}

.associated-subjob-data-header {
	text-transform: uppercase;
	font-size: $val-fontSizeRegular;
	color: $col-grey;
}

.associated-subjob-data-text {
	font-size: $val-fontSizeSmall;
	color: $col-grey;
}

.subjob-link {
	color: $col-blue;
	cursor: pointer;
	font-size: $val-fontSizeXBig;
}

.subjob-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.subjob-row-column {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.link {
	display: flex;
	flex-direction: row;
	color: $col-blue;
	cursor: pointer;
	margin-bottom: $val-spacing-m;
	align-items: center;
}

.project-sub-job-indicator {
	margin-right: $val-spacing-s;
}

.originals-container {
	& > *  + * {
		margin-top: $val-spacing-s;
	}
}