@import '../../../../styles/Colors.scss';
@import '../../../../styles/Constants.scss';

$var-label-width: 150px;

.items-button-wrapper {
	display: flex;
	flex-direction: row;
	padding: $val-spacing-m;
	border-top: 1px solid black;
}

.order-row {
	padding: 0 $val-spacing-xs;
}

.select-intentory-system {
	display: flex;
	align-items: center;
	background-color: $col-design-orange5;
	padding: $val-spacing-s $val-spacing-l;
	gap: $val-spacing-l;

	&__dropdown {
		background-color: $col-white;
		flex: 1;
		z-index: 10;
	}

	& > [class^="icon-close"] {
		color: $col-canceledRed;
		cursor: pointer;
		font-size: $val-fontSizeBig;

	}
}

.item-option {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $val-spacing-s;
	align-items: flex-start;
	margin-left: $val-spacing-s;

	&__details {
		font-size: $val-fontSizeSmall;
		color: $col-greyFontLight;
	}
}

.add-item-button {
	padding-top: $val-spacing-m;
}

.remove-item-button {
	padding-top: $val-spacing-l;
}

.item-container {
	border-top: 1px solid black;
	padding: $val-spacing-m;
}

.item-label {
	font-weight: bold;
	font-size: $val-fontSizeBig;
}

.form-container {
	background-color: $col-greyLight;
	padding-top: $val-spacing-m;
	padding-bottom: $val-spacing-m;
	padding-right: $val-spacing-s;
	padding-left: $val-spacing-s;
}

.section-container {
	background-color: white;
	padding: $val-spacing-m;
}

.total-cost-container {
	background-color: $col-greyBackground;
	padding: $val-spacing-m;
}

.section-title {
	color: $col-grey;
	text-transform: uppercase;
	font-size: $val-fontSizeBig;
	display: flex;
	flex-direction: column;
	margin-bottom: $val-spacing-m;
}

.form-title-container {
	background-color: white;
	padding: $val-spacing-m;
	border-bottom: 1px solid $col-greyBackground;
	display: flex;
	justify-content: space-between;
}
.form-title {
	font-weight: bold;
	font-size: $val-fontSizeBig;

	&__readonly {
		margin-left: $val-spacing-s;
		color: $col-design-grey10;
	}
}

.form-button-container {
	display: flex;
	flex-direction: row;

	& > * + * {
		margin-left: $val-spacing-xs;
	}
}

.total {
	padding-top: $val-spacing-l;
	font-weight: bold;
}

.field-value {
	margin-top: $val-spacing-s;
	margin-left: $val-spacing-s;
	margin-bottom: $val-spacing-s;
}

.name-value {
	margin-top: $val-spacing-s;
	margin-left: $val-spacing-s;
	margin-bottom: $val-spacing-s;
	font-weight: bold;
	padding: $val-spacing-xs;
}

.bold {
	font-weight: bold;
}

.bold-grey {
	font-weight: bold;
	color: $col-grey;
}

.dropdown-white-background {
	background-color: $col-white;
}

.right {
	margin-left: auto;
	text-align: right;
}

.error-message {
	color: $col-canceledRed;
}

.row-value {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.form {
	display: flex;
	flex-direction: column;

	&__menu-option {
		width: 100%;
		margin-left: $val-spacing-s;

		&__text {
			display: flex;

			&__name {
				flex: 1;
				display: flex;
				gap: $val-spacing-xs
			}
		}

		&__sub-text {
			color: $col-greyFontLight;
			font-size: $val-fontSizeSmall;
		}
	}
}

.order-table__status {
	width: $var-label-width;
}