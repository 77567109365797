@import '../../../../../../styles/Colors.scss';
@import '../../../../../../styles/Constants.scss';

$var-image-size: 50px;
$var-signature-size: 72px;

.side-panel {
	display: flex;
	flex-direction: column;
	background-color: $col-greyBackground;
	height: 100%;

	&__block {
		border-bottom: 1px solid $col-greyConnection;

		&--primary {
			border-left: 4px solid $col-darkBlueBorder;
			background-color: $col-blueBackground;
		}

		&--secondary {
			background-color: $col-white;
		}

		&--secondary-main {
			background-color: $col-greyLight;
		}

		&__header {
			display: flex;
			padding: $val-spacing-m $val-spacing-l;
			text-transform: uppercase;
			font-weight: bold;
			cursor: pointer;

			& > [class^="icon-"] {
				margin-left: $val-spacing-m;
				font-size: $val-fontSizeXBig;
			}

			&--main {
				color: $col-design-blue500;
			}

			&--primary {
				background-color: $col-blueBackgroundDark;
			}

		}

		&__body {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}

	}

	&__field {
		padding: $val-spacing-m $val-spacing-l;

		&--span-one {
			grid-column: span 1;
		}

		&--span-two {
			grid-column: span 2;
		}

		& > img {
			height: $var-image-size;
			width: $var-image-size;
		}

		&--completed {
			display: flex;
			justify-content: flex-end;
			gap: $val-spacing-s;
			padding-right: $val-spacing-m;

		}

		&__name {
			font-size: $val-fontSizeSmall;
			color: $col-design-gray6;

			&--repeated {
				margin-top: $val-spacing-s;
			}
		}

		&__value {
			font-weight: bold;
		}
	}

	&__signature-field {
		display: flex;
		flex-direction: column;
		grid-column: span 2;

		& > img {
			width: $var-signature-size;
			height: $var-signature-size;
		}
	}

	&__descriptive-text {
		color: $col-red;

		&--black-text {
			color: $col-black;
		}

		&--bold-text {
			font-weight: bold;
		}
	}

	&__loading {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
