@import '../../../src/styles/Colors.scss';
@import '../../../src/styles/Constants.scss';

$var-minimum-cell-width: 100px;
$var-display-cell-width: 50px;
$var-resizer-width: 16px;
$var-gap-size: $val-spacing-l;
$var-shadow: 0 2px 2px 0 $col-shadow;
$var-company-header-height: 64px;
$var-tabs-navigation-height: 64px;
$var-breadcrumbs-header-height: 56px;
$var-table-header-height: 50px;
$var-table-action-header-height: 64px;
$var-table-footer-height: 64px;
$var-dropdown-z-index: 1000;

@mixin icon {
	color: $col-greyMid;
	margin-left: $val-spacing-xs;
}

@mixin cell ($type) {
	font-weight: normal;
	min-width: $var-minimum-cell-width;
	display: flex;
	align-items: center;
	height: 100%;
	// margin-right: $val-spacing-m;
	// overflow: hidden;

	@if $type ==header {
		background-color: $col-greyBackground;
		// padding: $val-spacing-s 0;
		font-size: $val-fontSizeSmall;
	}

	@if $type ==regular {
		padding: $val-spacing-m $val-spacing-s;
		background-color: inherit;
	}

	@if $type ==sub_row {
		padding: 0 $val-spacing-s $val-spacing-m $val-spacing-s;
		background-color: inherit;
	}

	&:has(div > :global(.show.dropdown)) {
		z-index: 2;
		background-color: transparent;
	}

	&:has(div > :global(.show.dropup)) {
		z-index: 2;
		background-color: transparent;
	}


}

@mixin row {
	display: grid;
	align-items: start;
	gap: $var-gap-size;
	padding: 0 $val-spacing-l;
	border-bottom: 1px solid $col-greyConnection;
}

@mixin text-with-sort {
	cursor: pointer;
	padding: $val-spacing-l $val-spacing-s;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow-x: hidden;
}

@mixin resizer {
	background-color: $col-greyBackground;
	cursor: col-resize;
	min-width: $var-resizer-width;
	max-width: $var-resizer-width;
	// placing resizer to the right most part of column cell, due to existence of gap property
	margin-right: (-$var-gap-size);
	height: 100%;
}

@mixin table-grid ($type) {
	&__row {
		@include row;

		&__settings {
			display: flex;
			align-items: center;
			height: 100%;
			width: $val-spacing-m;

			[class^="icon-settings"] {
				cursor: pointer;
				color: $col-greyMid;
				font-size: $val-fontSizeSmall;

				&:hover {
					color: $col-black;
				}
			}
		}

		&__header-cell {
			@include cell($type);
			justify-content: space-between;

			&__text-with-sort {
				@include text-with-sort;

				&>[class^="icon-"] {
					@include icon;
				}
			}

			&__resizer {
				@include resizer;
			}

			&:last-child {

				// remove for last resizer so it doesn't push rows to the left
				&>span:last-child {
					margin-right: 0;
				}
			}
		}

		&__data-cell {
			@include cell($type);
		}

		&__sub-row-data-cell {
			@include cell(sub_row)
		}


		@if $type ==regular {
			cursor: pointer;
			background-color: inherit;
		}

		&--non-last {
			border-bottom: 0;
		}
	}

}

.container {
	display: grid;
	grid-template-columns: 1fr;
	max-width: 100%;

	&__table-container {
		display: grid;
		box-shadow: $var-shadow;
		border: 1px solid $col-greyConnection;
		background-color: $col-white;

		&__table-header {
			padding: $val-spacing-m $val-spacing-l;
			background-color: $col-greyLight;
			border-bottom: 1px solid $col-greyConnection;
		}

		&__table {
			display: grid;
			border-bottom: 1px solid $col-greyConnection;
			overflow: auto;

			@mixin head {
				background-color: $col-greyBackground;
				position: sticky;
				top: 0;
				z-index: 2;
				@include table-grid(header);
			}

			&__head {
				@include head;
			}

			@mixin body {
				height: calc(
					100vh 
					- $var-company-header-height 
					- $var-breadcrumbs-header-height 
					- $var-tabs-navigation-height 
					- $var-table-action-header-height 
					- $var-table-footer-height 
					- $val-spacing-xl
					- var(--extra-table-offset, 0px)
				);
				background-color: $col-greyBackground;
			}

			&__body {
				@include body;

				&__fit-content {
					@include body;
					height: fit-content;
					max-height: 500px;
					overflow-y: auto;
				}

				&__row-group {
					background-color: $col-white;

					&:hover {
						background-color: $col-orangeHover;
					}

					&--active-in-side-panel {
						background-color: $col-design-blue50;

						&:hover {
							background-color: $col-design-blue50;
						}
					}
				}

				@mixin no-rows {
					display: inline-block;
					position: sticky;
					left: 50%;
					background-color: $col-white;
					padding: $val-spacing-m;
					top: 50%;
					text-align: center;
					transform: translate(0, 50%);
					color: $col-greyMid;
					font-style: italic;
					height: fit-content;
				}

				&__no-rows {
					@include no-rows;

					&__fit-content {
						@include no-rows;
						top: 0;
						transform: translate(0, 0);
					}
				}

				@include table-grid(regular);
			}
		}

		// if there is a table header, reduce table height by table header's height
		&:has(.container__table-container__table-header) {
			.container__table-container__table__body {
				height: calc(100vh - $var-company-header-height - $var-breadcrumbs-header-height - $var-tabs-navigation-height - $var-table-header-height - $var-table-action-header-height - $var-table-footer-height - 4 * $val-spacing-xl);
			}
		}
	}

	&__side-panel {
		box-shadow: $var-shadow;
		border-top: 1px solid $col-greyConnection;
		border-bottom: 1px solid $col-greyConnection;
		border-right: 1px solid $col-greyConnection;
		background-color: $col-greyBackground;
		overflow: auto;

		&__header {
			height: calc($var-table-action-header-height + 2px); // plus border
			border-bottom: 1px solid $col-greyConnection;

			&:has(.container__side-panel__header__default) {
				display: flex;
				align-items: center;
				& > span {
					width: 100%;
					padding-left: $val-spacing-l;
				}
			}

			&__default {
				text-transform: uppercase;
				font-weight: bold;
			}
		}

		&__body {
			overflow: auto;
			max-height: 100%;
			height: calc(
					100vh
					- $var-company-header-height
					- $var-breadcrumbs-header-height
					- $var-tabs-navigation-height
					- $var-table-action-header-height
					- $val-spacing-xl
					- var(--extra-table-offset, 0px)
				);

			&__hint {
				padding: $val-spacing-l;
				color: $col-greyInactive;
			}
		}

	}

	// if there is a table header, reduce table height by table header's height
	&:has(.table-container__table-header) {
		.table-container__table__body {
			height: calc(
				100vh 
				- $var-company-header-height 
				- $var-breadcrumbs-header-height 
				- $var-tabs-navigation-height 
				- $var-table-header-height 
				- $var-table-action-header-height 
				- $var-table-footer-height 
				- 4 * $val-spacing-xl
				- var(--extra-table-offset, 0px)
			);
		}
	}
}

.fixed {
	position: sticky;
}

.last-fixed {
	border-right: 1px solid $col-greyConnection;

	// not moving resizer because we want visible right border
	&>span:last-child {
		margin-right: 0;
	}
}

.display-column {
	min-width: $var-display-cell-width;
}

.right-aligned-column-header {
	justify-content: flex-end;
}

.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
