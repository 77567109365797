@import "../../../styles/Constants.scss";
@import "../../../styles/Colors.scss";

$var-shadow: 0 1px 4px 0 $col-shadow;
$var-submit-action-top-offset: -16px;
$var-tabs-navigation-top-offset: 48px;
$var-lost-for-reason-width: 250px;

.work-request-preview {

	&__back-to-list {
		background-color: $col-white !important;
		margin-bottom: $val-spacing-m;
	}

	&__submit-section {
		display: flex;
		align-items: center;
		background-color: $col-white;
		padding: $val-spacing-m $val-spacing-l;
		z-index: 3;
		border-bottom: 2px solid $col-greyBackground;
		top: $var-submit-action-top-offset;
		position: sticky;

		& > * + * {
			margin-left: $val-spacing-xs;
		}

		&__in-progress-status {
			&>button>div {
				font-weight: bold;
				color: $col-yellow;
			}
		}

		&__lost-status {
			&>button>div {
				font-weight: bold;
				color: $col-canceledRed;
			}

			&__reason {
				max-width: $var-lost-for-reason-width;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		&__won-status {
			&>button>div {
				font-weight: bold;
				color: $col-green;
			}
		}

		&__locked-in-status {
			color: $col-blue;
			cursor: pointer;
			font-weight: bold;
			margin-left: $val-spacing-m;

			&:hover {
				color: $col-darkBlueBorder;
			}
		}

		&__status {
			flex: 1;
			display: flex;
			align-items: center;

			&--in-progress {
				color: $col-yellow;
			}

			&--won {
				background-color: $col-green;
			}

			&--lost {
				background-color: $col-canceledRed;
			}

			&--locked-in {
				color: $col-blue;
				cursor: pointer;

				&:hover {
					color: $col-darkBlueBorder;
				}
			}

			&__dropdown {
				>div>div {
					z-index: 100 !important;
				}
			}
		}

		&__actions {
			display: flex;
			gap: $val-spacing-xs;
		}

	}

	&__tabs-navigation {
		position: sticky;
		top: $var-tabs-navigation-top-offset;
	}

	&__tab-content {
		display: flex;
		flex-direction: column;
		gap: $val-spacing-m;
		margin-top: $val-spacing-s;

		&__section {
			display: grid;
			row-gap: $val-spacing-l;
			background-color: $col-white;
			box-shadow: $var-shadow;
			padding: $val-spacing-m $val-spacing-l;

			&__title {
				text-transform: uppercase;
				color: $col-design-grey11;
				row-gap: 0;
				font-size: $val-fontSizeSmall;
				margin-bottom: $val-spacing-m;
			}

			&__row {
				display: grid;
				grid-template-columns: repeat(8, 1fr);

				&__column-1 {
					grid-column: span 1;
				}

				&__column-2 {
					grid-column: span 2;
				}

				&__column-4 {
					grid-column: span 4;
				}

				&__column-6 {
					grid-column: span 6;
				}

				&__column-8 {
					grid-column: span 8;
				}

				&__centered-column {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	&__associated-work-request {
		&>a {
			display: flex;
			align-items: center;
			color: $col-blue;

			&>[class^="icon-external"] {
				margin-right: $val-spacing-s;
			}
		}
	}

}

@mixin cell-text {
	font-size: $val-fontSizeSmall;
}

.work-request-preview-table {
	&__cell-text {
		@include cell-text;

		&__blue {
			@include cell-text;
			color: $col-blue;
			text-transform: uppercase;
		}

		&__uppercase {
			@include cell-text;
			text-transform: uppercase;
		}
	}

	&__light-text {
		@include cell-text;
		color: $col-design-grey10;
		text-transform: uppercase;
	}
}