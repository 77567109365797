@import "../../../../styles/Constants.scss";
@import "../../../../styles/Colors.scss";

$var-shadow: 0 1px 4px 0 $col-shadow;
$var-submit-action-top-offset: -16px;
$var-tabs-navigation-top-offset: 48px;

.work-request-form {

	&__contact-dropdown-item {
		display: flex;
		flex-direction: column;

		&__main {
			font-size: $val-fontSizeRegular;
		}

		&__secondary {
			font-size: $val-fontSizeSmall;
			color: $col-greyFontLight;
		}
	}

	&__submit-section {
		display: flex;
		align-items: center;
		background-color: $col-white;
		padding: $val-spacing-m $val-spacing-l;
		z-index: 3;
		border-bottom: 2px solid $col-greyBackground;
		top: $var-submit-action-top-offset;
		position: sticky;

		& > * + * {
			margin-left: $val-spacing-xs;
		}

		&__hint {
			color: $col-greyFontLight;
			font-style: italic;
			flex: 1;
		}
	}

	&__tabs-navigation {
		position: sticky;
		top: $var-tabs-navigation-top-offset;
		z-index: 3;
	}

	&__tab-content {
		display: flex;
		flex-direction: column;
		gap: $val-spacing-m;
		margin-top: $val-spacing-s;

		&__form-section {
			background-color: $col-white;
			box-shadow: $var-shadow;
			padding: $val-spacing-m $val-spacing-l;

			&__title {
				text-transform: uppercase;
				color: $col-design-grey11;
				font-size: $val-fontSizeSmall;
				margin-bottom: $val-spacing-m;
			}

			&__row {
				display: grid;
				gap: $val-spacing-l;
				grid-template-columns: repeat(8, 1fr);

				&__column-1 {
					grid-column: span 1;
				}

				&__column-2 {
					grid-column: span 2;
				}

				&__column-4 {
					grid-column: span 4;
				}

				&__column-6 {
					grid-column: span 6;
				}

				&__column-8 {
					grid-column: span 8;
				}

				&__centered-column {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	&__contact {
		background-color: $col-white;
		margin: (-$val-spacing-m) 0 (-$val-spacing-l) (-$val-spacing-l);
	}

	&__billing-information-title {
		padding-bottom: $val-spacing-m;
	}

	&__additional-contact-container {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding-bottom: $val-spacing-l;


		& > button {
			margin-top: $val-spacing-l;
		}
	}

	&__delete-button {
		margin-top: $val-spacing-m;

		& > [class^="icon-delete"] {
			color: $col-grey;
			font-size: $val-fontSizeBig;
			cursor: pointer;
			margin-top: calc($val-spacing-l + $val-spacing-s);

			&:hover {
				color: $col-black;
			}
		}
	}

	&__additional-contact {
		margin: 0 0 (-$val-spacing-l) (-$val-spacing-l);
		background-color: $col-white;
		padding-top: $val-spacing-s;
		padding-bottom: $val-spacing-s;
	}

	&__waste-types {
		padding: $val-spacing-m 0;
	}

	&__radio-group {
		padding-bottom: $val-spacing-m;
	}

	&__tab {
		display: flex;
		gap: $val-spacing-xs;
		align-items: center;

		& > [class^="icon-warning"] {
			color: $col-canceledRed;
			font-size: $val-fontSizeBig;

		}
	}

	&__job-hazard-assessment-status-error {
		padding-bottom: $val-spacing-m;
		color: $col-canceledRed;
		font-style: italic;
	}

	&__add-field-button {
		width: fit-content;
		color: $col-blue;
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-top: $val-spacing-m;

		& [class^="icon-plus"] {
			font-size: $val-fontSizeBig;
			margin-right: $val-spacing-s;
		}
		&:hover {
			color: $col-darkBlueBorder;
		}
	}

	&__deliverables {
		margin-bottom: $val-spacing-m;
	}

	&__trucks-and-equipment {

		&__column {
			display: flex;
			flex-direction: column;
			gap: $val-spacing-s;
		}

		&__number-of-employees {
			display: flex;
			align-items: center;
			padding: $val-spacing-l 0;

			&__field-column {
				& > div {
					margin-bottom: 0 !important;
				}
			}

			&__field {
				border: 0 !important;
				border-bottom: 1px solid $col-greyMid !important;
				width: 100px !important;
			}
		}

		&__additional-field {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: $val-spacing-s;

			& > div {
				margin-bottom: 0 !important;
			}

			&__name {
				background-color: red;
				margin: 0 !important;
			}

			&__delete-button {
				& > [class^="icon-delete"] {
					color: $col-grey;
					font-size: $val-fontSizeBig;
					cursor: pointer;

					&:hover {
						color: $col-black;
					}
				}
			}
		}
	}

}
