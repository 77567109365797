$min-search-input-width: 300px;

.schedule-board-header {
	&__second {
		height: $val-headerHeight;
		width: 100%;
		color: $col-black;
		background: $col-white;
		border-bottom: 1px solid $col-greyConnection;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 $val-spacing-l;
		z-index: 4;
		user-select: none;

		&__left {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			position: relative;

			& > div + button, & > button + div {
				// First item excluded by condition
				margin-left: $val-spacing-s;
			}

			&__search {
				min-width: $min-search-input-width;
				position: relative;

				&__form {
					background-image: url("/images/inputs/ic_search_disabled.svg");
					background-repeat: no-repeat;
					background-position: $val-spacing-s center;
					padding-left: $val-spacing-l;
				}

				.input-group-append {
					height: 100%;
					min-width: 2 * $val-spacing-xl;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					margin-right: $val-spacing-s;
					color: $col-greyFontLight;

					.match {
						margin-right: $val-spacing-xs;
						display: flex;
						justify-content: center;
						align-items: center;
						top: 0;
						height: 100%;
						display: flex;
						align-items: center;
						z-index: 2;

						span {
							font-size: $val-fontSizeSmall;
							padding: 0 $val-spacing-xs;
							line-height: $val-lineHeightSmall;
						}
					}

					.icon-close {
						cursor: pointer;
						font-size: $val-fontSizeBig;
					}

					.icon-left, .icon-right {
						cursor: pointer;
						font-size: $val-fontSizeSmall;
					}
				}
			}

			&__filter {
				min-width: auto;
				padding: 0 $val-spacing-m;

				&:first-of-type {
					@extend .btn--open-right;
				}

				&:last-of-type {
					@extend .btn--open-left;
				}

			}

			.form-group {
				margin-bottom: 0;
			}
		}

		&__center {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: center;
			width: 100%;
			padding: 0 $val-spacing-s;

			& .labor-statistics {
				display: flex;
				overflow: hidden;
				border-left: 1px solid $col-greyFontLight;
				border-right: 1px solid $col-greyFontLight;

				&.--centered {
					justify-content: center;
					border: none;
				}

				&.--loading {
					width: 100%;
					border: none;
					justify-content: center;

					& > div {
						padding: $val-spacing-s;

						&:after {
							position: relative;
							top: 12px;
							height: 51px;
						}
					}
				}
			}
		}

		&__right {
			display: flex;

			& > div + div {
				margin-left: $val-spacing-s;
			}
		}
	}
}